import { useContext } from 'react'
import { AppContext } from '../../Protected'
import DesktopTable from './desktopTable'
import MobileTable from './mobileTable'
import { Box } from '@mui/material'

const TableWrapper = (props: any) => {
  const { isDesktop, getHeaderAddOns } = useContext(AppContext)

  return (
    <Box p={!isDesktop ? '5px 10px 100px 20px' : '0px'}>
      {isDesktop ? (
        <DesktopTable {...props} getHeaderAddOns={getHeaderAddOns} />
      ) : (
        <MobileTable {...props} getHeaderAddOns={getHeaderAddOns} />
      )}
    </Box>
  )
}

export default TableWrapper
