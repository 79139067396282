import { useState, useEffect, ChangeEvent } from 'react'
import Box from '@mui/material/Box/Box'
import Stack from '@mui/material/Stack/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import CheckIcon from '@mui/icons-material/Check'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import CustomDialog from '../customDialog'
import Post, { PostTypes } from './post'
import CustomSwitch from '../customSwitch'
import {
  useAddAccountPost,
  useScrapeAccountPosts,
} from '../../services/account.service'
import { apiResponseSimplify } from '../../helpers/utils'
import { AccountPostProps } from '../../types/account.types'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import CustomButton from '../customButton'
import PicturePlaceholder from '../../assets/images/avatars/pp-placeholder.png'

type PostsTypes = {
  label: string
  isDesktop: boolean
  account: any
  posts: any[]
}

const limit = 4
const scrapePostLimit = 8

export default function Posts(props: PostsTypes) {
  const { account, posts, label } = props
  const isDesktop = props.isDesktop
  const [changePostOpen, setChangePostOpen] = useState(false)
  const [isAddDialog, setIsAddDialog] = useState(false)
  const [postToChange, setPostToChange] = useState(null)
  const [selectedPost, setSelectedPosts] = useState<any[]>([])
  const [advanced, setAdvanced] = useState(false)
  const [scrapedPosts, setScrapedPosts] = useState<any[]>([])
  const [maxId, setMaxId] = useState('')
  const [payload, setPayload] = useState<AccountPostProps[]>([])
  const [isLoadMore, setIsLoadMore] = useState(false)

  const isPostLessThanLimit = posts.length < limit

  const [onAddPosts] = useState(true)

  const { isLoading, mutate: scrapePosts } = useScrapeAccountPosts(account?.id)
  const { mutate: add } = useAddAccountPost(account?.id)

  useEffect(() => {
    if (!changePostOpen) {
      setSelectedPosts([])
    }
  }, [changePostOpen])

  const handleOpenDialogPost = (isAdd: boolean = false, post: any = null) => {
    setChangePostOpen(!changePostOpen)
    setIsAddDialog(isAdd)
    setPostToChange(post)
    handleScrapePosts(scrapePostLimit, '')
  }

  const loadMorePosts = () => {
    setIsLoadMore(true)
    handleScrapePosts(scrapePostLimit, maxId)
  }

  const handleScrapePosts = (count: number, maxId: string) => {
    scrapePosts(
      { count, max_id: maxId },
      {
        onSuccess: (data) => {
          const scraped = apiResponseSimplify(data, true)
          const scrapedItems = scraped?.response?.body?.items ?? []
          setMaxId(scraped?.response?.body?.next_max_id ?? '')
          setScrapedPosts([...scrapedPosts, ...scrapedItems])
        },
      }
    )
  }

  const handleSelectPost = (post: any) => {
    const simplifiedPost = {
      shortcode: post?.code,
      like_count: post?.like_count ?? 0,
      comment_count: post?.comment_count ?? 0,
      view_count: post?.view_count ?? 0,
      image_url: post?.image_versions2?.candidates[0]?.url ?? '',
    }
    if (!isAddDialog) {
      setSelectedPosts([{ ...post, shortcode: post?.code }])
      setPayload([simplifiedPost])
      return
    }

    const postExist = selectedPost.find((p) => p.shortcode === post.code)

    if (posts.length + selectedPost.length === limit && !postExist) {
      return
    }

    setSelectedPosts(
      !postExist
        ? [...selectedPost, { ...post, shortcode: post?.code }]
        : selectedPost.filter((p) => p.id !== post.id)
    )

    setPayload(
      !postExist
        ? [...payload, simplifiedPost]
        : payload.filter((p) => p.shortcode !== simplifiedPost.shortcode)
    )
  }

  const handleAddPost = () => {
    if (onAddPosts) {
      payload.forEach((post: AccountPostProps) => {
        add(post, {
          onSuccess: () => {
            // refetch here the posts
            setChangePostOpen(false)
          },
        })
      })
    }
  }

  const handleChangePost = () => {}

  const handleAdvanced = (event: ChangeEvent<HTMLInputElement>) => {
    setAdvanced(event.target.checked)
  }

  const getLoaders = (keyPrefix: string = 'loader') => {
    return Array(4)
      .fill(null)
      .map((_e, index) => {
        return (
          <Grid
            key={`${keyPrefix}-${index}`}
            item
            xs={6}
            md={3}
            position="relative"
          >
            <RectangularSkeleton height="101px" width="auto" />
          </Grid>
        )
      })
  }

  const isToday = label === 'Today'

  return (
    <Stack mt={3} p="2px">
      <Grid color="#261F72" mb={2} container>
        <Grid item xs={isToday ? 3 : 12} md={7}>
          <Typography fontSize={24} fontWeight={700}>
            {props.label}
          </Typography>
        </Grid>
        {/* {isPostLessThanLimit && isToday && (
          <>
            <Grid
              item
              xs={9}
              md={3}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Typography
                fontSize={isDesktop ? 16 : 14}
                fontWeight={600}
                color="#00B67A"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleOpenDialogPost(true)}
              >
                Do you want to add more posts?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              mt={isDesktop ? 'unset' : '5px'}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box
                pl={isDesktop ? '30px' : 'unset'}
                width="100%"
                sx={{
                  '.MuiLinearProgress-root': {
                    bgcolor: 'rgb(32 185 143 / 30%)!important',
                    '.MuiLinearProgress-bar': {
                      bgcolor: '#00B67A!important',
                    },
                  },
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography>Limit</Typography>
                  <Typography color="#000000" sx={{ opacity: 0.5 }}>
                    {posts.length} / {limit} Posts
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={(posts.length / limit) * 100}
                />
              </Box>
            </Grid>
          </>
        )} */}
      </Grid>
      <Grid
        sx={{
          borderRadius: '10px',
        }}
        container
        spacing={3}
      >
        {posts.map((post: PostTypes) => (
          <Post
            key={`post-${post.shortcode}`}
            post={post}
            isDesktop={isDesktop}
            onChangePost={handleOpenDialogPost}
            isOpen={changePostOpen}
            isAddDialog={isAddDialog}
          />
        ))}
        {isPostLessThanLimit && isToday && (
          <Post
            isAdd
            isDesktop={isDesktop}
            onChangePost={handleOpenDialogPost}
            isOpen={changePostOpen}
            isAddDialog={isAddDialog}
          />
        )}
      </Grid>
      <CustomDialog
        title={`${isAddDialog ? 'Select' : 'Change'} Post`}
        open={changePostOpen}
        onClose={() => {
          setChangePostOpen(false)
          setScrapedPosts([])
        }}
        isDesktop={isDesktop}
      >
        <Box
          color="#261F72"
          width="100%"
          mb="50px"
          sx={{
            img: {
              width: '100%',
            },
          }}
        >
          <Box pb="24px" borderBottom="1px solid #00000017">
            <Typography fontWeight={600} fontSize={18} mb={2}>
              {postToChange ? (
                <>Post to Change</>
              ) : (
                <>Recently used ({posts.length})</>
              )}
            </Typography>
            <Grid container spacing={2}>
              {(postToChange
                ? [postToChange]
                : [...posts, ...selectedPost]
              ).map((post: any, i: number) => (
                <Grid
                  key={`recent-post-${i}`}
                  item
                  xs={6}
                  md={3}
                  position="relative"
                >
                  <Box
                    width="20px"
                    height="20px"
                    bgcolor="#00B67A"
                    display="flex"
                    borderRadius="50%"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    right="6px"
                    top="23px"
                    sx={{
                      svg: {
                        fontSize: 11,
                        color: '#fff',
                      },
                    }}
                  >
                    <CheckIcon />
                  </Box>
                  <Box
                    minWidth="101px"
                    height="101px"
                    sx={{
                      background: `url(${
                        post?.image_versions2?.candidates[0]?.url
                          ? `https://www.followerpackages.com/services/image_orig.php?url=${post?.image_versions2?.candidates[0]?.url}`
                          : PicturePlaceholder
                      }) no-repeat`,
                      backgroundSize: 'cover',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Stack
            pt="24px"
            pb="24px"
            borderBottom="1px solid #00000017"
            alignItems="center"
          >
            <Grid container spacing={2}>
              {isLoading && !isLoadMore && getLoaders()}
              {scrapedPosts.map((post: any, index: number) => {
                const isPostSelected = selectedPost.find(
                  (p) => p.shortcode === post.code
                )
                const isDisabled = posts.find(
                  (p: any) => p.shortcode === post.code
                )
                return (
                  <Grid
                    key={`recent-post-${index}`}
                    item
                    xs={6}
                    md={3}
                    position="relative"
                    onClick={() => !isDisabled && handleSelectPost(post)}
                  >
                    <Box
                      width="20px"
                      height="20px"
                      position="absolute"
                      color="#fff"
                      right="6px"
                      top="22px"
                    >
                      {isPostSelected || isDisabled ? (
                        <Box
                          width="22px"
                          height="22px"
                          bgcolor={isDisabled ? '#9ab5ac' : '#00B67A'}
                          display="flex"
                          borderRadius="50%"
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          sx={{
                            svg: {
                              fontSize: 11,
                              color: '#fff',
                            },
                          }}
                        >
                          <CheckIcon />
                        </Box>
                      ) : (
                        <PanoramaFishEyeIcon />
                      )}
                    </Box>
                    <Box
                      minWidth="101px"
                      height="101px"
                      sx={{
                        background: `url(https://www.followerpackages.com/services/image_orig.php?url=${post?.image_versions2?.candidates[0]?.url}) no-repeat`,
                        backgroundSize: 'cover',
                      }}
                    />
                  </Grid>
                )
              })}
              {isLoading && isLoadMore && getLoaders('loader-more')}
            </Grid>
            {!isLoading && (
              <Button
                variant="outlined"
                sx={{
                  mt: '30px',
                  borderRadius: '5px',
                  color: '#00B67A',
                  border: '1px solid #00B67A',
                  textTransform: 'capitalize',
                  letterSpacing: 1.2,
                  width: '200px',
                  fontSize: 14,
                  fontWeight: 600,
                  p: '10px',
                  '&:hover': {
                    border: '1px solid #00B67A',
                  },
                }}
                onClick={loadMorePosts}
              >
                Load More Posts
              </Button>
            )}
          </Stack>
          <Box mt="18px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={600} fontSize={18} color="#000">
                Advanced Options
              </Typography>
              <CustomSwitch
                checked={advanced}
                labelPlacement="start"
                onChange={handleAdvanced}
                customStyles={{
                  color: '#fff',
                  zIndex: 1,
                }}
              />
            </Box>
            <FormControl
              variant="filled"
              disabled={!advanced}
              sx={{
                m: '5px 0px',
                width: '100%',
                '.MuiInputBase-root': {
                  border: '1px solid rgba(81, 69, 158, 0.18)!important',
                },
                '.MuiInputBase-root, .MuiSelect-select.MuiSelect-filled:focus':
                  {
                    bgcolor: '#fff!important',
                    borderRadius: '10px',
                    '&:before, &:after': {
                      borderBottom: '0px!important',
                    },
                    '.MuiSelect-select': {
                      pr: '40px',
                      color: '#281C58',
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                '.MuiFormLabel-root, .MuiFormLabel-root.Mui-focused': {
                  color: '#5A5A5A!important',
                  opacity: 0.5,
                },
              }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Delivery Speed
              </InputLabel>
              <Select
                labelId="subscription-type-label-label"
                id="subscription-type-label"
                defaultValue={40}
                onChange={() => {}}
              >
                <MenuItem value={10}>In 10 Minutes</MenuItem>
                <MenuItem value={20}>In 20 Minutes</MenuItem>
                <MenuItem value={30}>In 30 Minutes</MenuItem>
                <MenuItem value={40}>In 40 Minutes</MenuItem>
                <MenuItem value={50}>In 50 Minutes</MenuItem>
                <MenuItem value={60}>In 1 Hour</MenuItem>
              </Select>
            </FormControl>
            {/* <CustomButton
              className="likester-button"
              label={`${postToChange ? 'Change' : 'Add'} Post`}
              onClick={postToChange ? handleChangePost : handleAddPost}
              disabled={isLoading}
              sx={{
                mt: 1,
                letterSpacing: 1.2,
                float: 'right',
                width: isDesktop ? 'unset' : '100%',
                p: '12px 20px 10px',
              }}
            /> */}
          </Box>
        </Box>
      </CustomDialog>
    </Stack>
  )
}
