import { useEffect, useState } from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import moment from 'moment'

import TableWrapper from '../components/table-wrapper/tableWrapper'
import CustomCheckbox from '../components/customCheckbox'
import { useContext } from 'react'
import { AppContext } from '../Protected'
import OptionPopover from '../components/table-wrapper/optionPopover'
import { useOrders } from '../hooks/useOrders'
import {
  capitalizeUnderlinedLabel,
  getServiceTypeLabel,
  numValChecker,
} from '../helpers/utils'
import { capitalize } from 'lodash'
import { SearchContext } from '../contexts/search.context'
import { useGetFile } from '../services/file.service'
import PicturePlaceholder from '../assets/images/avatars/pp-placeholder.png'

const tableColumns = [
  {
    key: 'date',
    label: 'Date & Time',
    align: 'center',
    customValue: (data: any) => {
      return <span>{moment(data?.created_at).format('M-D-YYYY | h:mmA')}</span>
    },
  },
  {
    key: 'subscriptionId',
    label: 'Subscription ID',
    customValue: (data: any) => {
      return <span>{data?.subscription_item?.subscription?.id}</span>
    },
  },
  {
    key: 'type',
    label: 'Type',
    customValue: (data: any) => {
      return (
        <span>
          {getServiceTypeLabel(data?.subscription_item?.package?.service)}
        </span>
      )
    },
  },
  // {
  //   key: 'amount',
  //   label: 'Amount',
  //   customValue: (data: any) => {
  //     return <span>${data?.subscription_item?.price}</span>
  //   },
  // },
  {
    key: 'customerEmail',
    label: 'Customer Email',
    customValue: (data: any) => {
      return <span>{data?.subscription_item?.subscription?.user?.email}</span>
    },
  },
  {
    key: 'instagramId',
    label: 'Instagram Id',
    customValue: (data: any) => {
      return (
        <span>
          {data?.subscription_item?.subscription?.account?.instagram?.user?.id}
        </span>
      )
    },
  },
  {
    key: 'username',
    label: 'Username',
    customValue: (data: any) => {
      return (
        <span>
          @{data?.subscription_item?.subscription?.account?.user_name}
        </span>
      )
    },
  },
  {
    key: 'status',
    label: 'Status',
    customValue: (data: any) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            '& .status-icon': {
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              mr: 1,
            },
            '&.cancelled': {
              '& .status-icon': { backgroundColor: '#FF4848' },
            },
            '&.completed': {
              '& .status-icon': { backgroundColor: '#27A857' },
            },
            '&.pending': {
              '& .status-icon': { backgroundColor: '#FF9548' },
            },
            '&.in_progress': {
              '& .status-icon': { backgroundColor: '#0080ff' },
            },
          }}
          className={data.status}
        >
          <div className="status-icon" />{' '}
          {capitalizeUnderlinedLabel(data?.status) ?? ''}
        </Box>
      )
    },
  },
]

const contextMenu = [
  {
    callBack: () => {},
    label: 'Resend to SMM',
  },
  {
    callBack: () => {},
    label: 'Refund Order',
  },
  {
    callBack: () => {},
    label: 'Cancel Order',
  },
]

const OrdersScreen = () => {
  const { isDesktop } = useContext(AppContext)
  const { globalSearchVal, fireSearch, setSearchLabel, setFireSearch } =
    useContext(SearchContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeRow, setActiveRow] = useState('')
  const isContextMenuOpen = Boolean(anchorEl)
  const [sort, setSort] = useState('-created_at')
  const [page, setPage] = useState(1)

  const { isFetching, data, refetch } = useOrders(
    `per_page=15&page=${page}&search=${globalSearchVal}&sort_by=${sort}`
  )

  const { meta = {}, data: rows = [] } = data ?? {}

  useEffect(() => {
    setTimeout(() => refetch(), 100)
  }, [page, sort]) //eslint-disable-line

  useEffect(() => {
    if (fireSearch) {
      if (page > 1) {
        return setPage(1)
      }
      refetch()
    }
  }, [fireSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetching) setFireSearch(false)
  }, [isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchLabel('Search Order')
  }, []) // eslint-disable-line

  const handleOpenContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setActiveRow(row.id)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseContextMenu = () => {
    setAnchorEl(null)
  }

  const CollapsibleComponent = (data: any, isDesktop: boolean) => {
    const account = data?.subscription_item?.subscription?.account
    const photo = account?.photo
    const { data: file, refetch: getFile } = useGetFile(photo)

    useEffect(() => {
      if (photo) {
        getFile()
      }
    }, [photo]) // eslint-disable-line

    const contextMenuComponent = (
      <Box p={isDesktop ? 3 : 0} pt={isDesktop ? 4 : 0}>
        <OptionPopover
          id={`context-menu-button-${data.id}`}
          isContextMenuOpen={isContextMenuOpen}
          anchorEl={anchorEl}
          handleCloseContextMenu={handleCloseContextMenu}
          handleOpenContextMenu={handleOpenContextMenu}
          contextMenu={[]}
          row={data}
          activeRow={activeRow}
          sx={{
            color: '#fff',
            backgroundColor: 'rgb(255 255 255 / 35%)',
            '&.active, &:hover': {
              backgroundColor: 'rgb(255 255 255 / 75%)',
              color: '#f4f7ff',
            },
          }}
        />
      </Box>
    )

    return (
      <Box display={isDesktop ? 'flex' : 'block'}>
        {isDesktop && (
          <Box
            textAlign="center"
            p={3}
            sx={{
              backgroundColor: '#3B56FC',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
            }}
          >
            <Box
              sx={{
                height: '91.13px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box
                borderRadius="50%"
                width="30px"
                height="30px"
                padding={isDesktop ? '27px' : '54px'}
                display="flex"
                sx={{
                  background: `url(${file ?? PicturePlaceholder})`,
                  backgroundSize: 'cover',
                }}
              />
              <CustomCheckbox
                checked={true}
                onChange={() => {}}
                parentSx={{
                  position: 'absolute',
                  bottom: '12px',
                  right: '7px',
                }}
                sx={{
                  backgroundColor: '#68C9FF',
                  border: '1px solid #fff',
                  borderRadius: '50%',
                  width: '12px',
                  height: '12px',
                  p: '1px',
                }}
                iconSx={{
                  fontSize: 10,
                }}
              />
            </Box>
            <Typography mt={2} fontSize={17} fontWeight={700}>
              {account?.instagram?.user?.full_name ?? ''}
            </Typography>
            <Typography fontSize={14}>@{account?.user_name ?? ''}</Typography>
          </Box>
        )}
        <Box
          p={isDesktop ? 4 : 2}
          flex={1}
          pb={1}
          display={isDesktop ? 'flex' : 'block'}
          flexDirection="column"
          flexWrap="wrap"
          // height={isDesktop ? '240px' : 'unset'}
          width={isDesktop ? '100%' : 'unset'}
        >
          {!isDesktop && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
              mb={3}
            >
              <Typography
                fontSize={15}
                fontWeight="normal"
                color="#fff"
                sx={{ opacity: 0.5 }}
              >
                Additional Order Details
              </Typography>
              {/* {contextMenuComponent} */}
            </Box>
          )}
          <Box
            display={isDesktop ? 'flex' : 'block'}
            justifyContent="space-between"
          >
            {[
              { label: 'APP ID', value: '252625185132' },
              { label: 'Upsell', value: 'N / A' },
              // { label: 'Coupon Discount', value: 'Not Used' },
              { label: 'IP Address', value: '192.168.2.17' },
              // { label: 'Method of Payment', value: 'Credit / Debit Card' },
              // {
              //   label: 'Transaction ID (checkout.com)',
              //   value: '1252-25636-68789',
              // },
              // {
              //   label: 'Payee Email Address',
              //   value: '7-7-2022 Wednesday | 12:03AM',
              // },
              // {
              //   label: 'Full Payment Log (checkout.com)',
              //   value: '7-7-2022 Wednesday | 12:03AM',
              // },
              // {
              //   label: 'Date of Expiration / Renewal',
              //   value: '7-8-2022 Thursday | 12:00AM',
              // },
            ].map(({ label, value }: any) => (
              <Box
                key={`another-info-${label}`}
                mb={isDesktop ? 3 : 1.5}
                display={isDesktop ? 'block' : 'flex'}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  fontSize={15}
                  fontWeight="normal"
                  color="#fff"
                  sx={{ opacity: 0.5 }}
                >
                  {label}
                </Typography>
                <Typography
                  fontSize={15}
                  mt="5px"
                  fontWeight={400}
                  color="#fff"
                  textAlign={isDesktop ? 'left' : 'right'}
                >
                  {value}
                </Typography>
              </Box>
            ))}
            <Box
              mb={3}
              display={isDesktop ? 'block' : 'flex'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                fontSize={15}
                fontWeight="normal"
                color="#fff"
                sx={{ opacity: 0.5 }}
              >
                Status
              </Typography>
              <Box display="flex" alignItems="end">
                <CustomCheckbox
                  checked={true}
                  onChange={() => {}}
                  parentSx={{ mr: 1 }}
                  sx={{
                    backgroundColor: 'success.light',
                    borderRadius: '50%',
                  }}
                />
                <Typography
                  fontSize={15}
                  mt="5px"
                  fontWeight={600}
                  color="#fff"
                  textAlign={isDesktop ? 'left' : 'right'}
                >
                  {capitalize(
                    data?.subscription_item?.subscription?.user?.status ?? ''
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* {isDesktop && contextMenuComponent} */}
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableWrapper
        rows={rows}
        columns={tableColumns}
        title="Orders"
        collapsible={true}
        isLoading={isFetching}
        mobileHeader={(data: any) => (
          <Box display="flex" alignItems="center">
            {/* <CustomCheckbox checked={false} onChange={() => {}} /> */}
            <Avatar sx={{ height: '37px', width: '37px', bgcolor: red[500] }}>
              R
            </Avatar>
            <Box>
              <Typography ml={1} fontSize={16} color="#261F72">
                {data?.name ?? ''}
              </Typography>
              <Typography ml={1} fontSize={14} color="#261F72">
                {data?.username ?? ''}
              </Typography>
            </Box>
          </Box>
        )}
        collapsibleComponent={(row: any) =>
          CollapsibleComponent(row, isDesktop)
        }
        sortByOptions={{
          '-created_at': 'Created at',
          status: 'Status',
        }}
        onSort={setSort}
        sort={sort}
        onPageChange={setPage}
        total={numValChecker(meta.total)}
        currentPage={numValChecker(meta.current_page)}
        pageCount={numValChecker(meta.last_page)}
        pageFrom={numValChecker(meta.from)}
        pageTo={numValChecker(meta.to)}
      />
    </Box>
  )
}

export default OrdersScreen
