import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Checkbox } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

interface CustomCheckboxProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  color?: string
  sx?: object
  parentSx?: object
  disabled?: boolean
  iconSx?: object
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  label,
  color = 'primary.main',
  sx = {},
  parentSx = {},
  iconSx = {},
  disabled = false,
}) => {
  return (
    <FormControlLabel
      sx={{ ml: 'unset', ...parentSx }}
      control={
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '18px',
              height: '18px',
              borderRadius: '2px',
              border: checked ? '2px solid transparent' : '2px solid #999',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              backgroundColor: checked ? color : '#fff',
              ...sx,
              svg: {
                color: '#fff!important',
                fontSize: 18,
                ...iconSx,
              },
            }}
            onClick={() => onChange({} as React.ChangeEvent<HTMLInputElement>)}
          >
            {checked && <DoneIcon />}
          </Box>
          <Checkbox
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            sx={{ display: 'none' }}
          />
        </Box>
      }
      label={label}
    />
  )
}

export default CustomCheckbox
