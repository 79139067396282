export const statuses = {
  cancelled: 'Cancelled',
  paused: 'Paused',
  active: 'Active',
}

export const couponStatuses = {
  expired: 'Expired',
  scheduled: 'Scheduled',
  active: 'Active',
  saturated: 'Saturated',
}

export const userStatuses = {
  banned: 'Banned',
  suspended: 'Suspended',
  active: 'Active',
  banned_ip: 'Banned IP Address',
}

export const rangeOfLikes = {
  '50': [10, 50],
  '100': [50, 100],
  '250': [50, 250],
  '500': [100, 500],
  '1000': [500, 1000],
  '1500': [500, 1500],
}
