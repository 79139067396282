import { Button, CircularProgress } from '@mui/material'

export default function CustomButton(props: any) {
  const { sx, isLoading, label, ...rest } = props
  return (
    <Button
      sx={{
        '&.likester-button': {
          bgcolor: '#00B67A',
          borderRadius: '10px',
          fontWeight: 500,
          fontSize: '18px',
          boxShadow: 'none',
          padding: '10px 20px',
          color: '#fff',
          textTransform: 'capitalize',
        },
        '&.likester-button-outline': {
          bgcolor: '#fff',
          borderRadius: '10px',
          fontWeight: 500,
          fontSize: '18px',
          boxShadow: 'none',
          padding: '10px 20px',
          color: '#27A857',
          border: '1px solid #27A857',
          textTransform: 'capitalize',
        },
        '&.Mui-disabled.likester-button': {
          bgcolor: 'rgba(0, 0, 0, 0.12)',
        },
        ...sx,
      }}
      variant="contained"
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? (
        <CircularProgress
          data-testid="button-loading-icon"
          sx={{
            width: '15px!important',
            height: '15px!important',
            mr: '5px!important',
          }}
        />
      ) : null}
      {label || ''}
    </Button>
  )
}
