import { useContext, ReactNode, useCallback, useEffect } from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Avatar from '@mui/material/Avatar'
import { debounce } from 'lodash'

import backgroundImg from '../../assets/images/background.png'
import { ReactComponent as NotificationBell } from '../../assets/images/icons/notification-bell.svg'
import Logo from '../../assets/images/logo.png'
import { AppContext } from '../../Protected'
import Typography from '@mui/material/Typography'
import { SearchContext } from '../../contexts/search.context'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import IconSkeleton from '../skeletons/icon.skeleton'
import CustomerAddon from '../addons/customer.addon'
import { useLocation } from 'react-router'

const ContentWrapper = (props: any) => {
  const { setGlobalSearchVal, hideSearch } = useContext(SearchContext)
  const {
    isDesktop,
    userDetails,
    activeRoute,
    isLoading,
    isCustomer,
    headerAddons,
    isAdmin,
  } = useContext(AppContext)
  const location = useLocation()

  const hasAddOn =
    (isCustomer && location.pathname === '/') ||
    (isAdmin && location.pathname !== '/')

  useEffect(() => {
    setGlobalSearchVal('')
    hideSearch(!!activeRoute?.hideSearch)
  }, [activeRoute]) // eslint-disable-line

  const isDashboard = activeRoute?.path === '/'
  const hideTitle = activeRoute?.hideTitle

  const mobileStyles = !isDesktop
    ? {
        backgroundSize: 'auto 450px',
        backgroundPosition: 'right top',
      }
    : {}

  const mobileHeaderStyles = !isDesktop
    ? {
        bgcolor: '#fff',
        boxShadow:
          'rgb(0 0 0 / 5%) 0px 2px 2px -1px, rgb(0 0 0 / 5%) 0px 1px 1px 0px, rgb(0 0 0 / 5%) 0px 1px 3px 0px',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      }
    : {}

  const getAvatar = () => {
    let photoSrc = ''

    if (userDetails?.photo) {
      photoSrc = userDetails.photo
    }

    if (userDetails?.avatar) {
      photoSrc = require('../../assets/images/avatars/' +
        userDetails?.avatar +
        '.png')
    }

    if (isLoading) {
      return <IconSkeleton />
    }

    return photoSrc ? (
      <Avatar
        sx={{
          width: 45,
          height: 45,
          bgcolor: '#' + (userDetails?.avatar ?? '2ecc71'),
          ml: '20px',
        }}
        src={photoSrc}
      />
    ) : (
      <Avatar sx={{ width: 45, height: 45, bgcolor: '#2ecc71', ml: '20px' }}>
        {userDetails?.name?.charAt(0)}
      </Avatar>
    )
  }

  return (
    <Box
      width="100%"
      height={isDesktop ? '100vh' : 'auto'}
      overflow={isDesktop ? 'hidden' : 'auto'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: !hasAddOn ? `url(${backgroundImg}) no-repeat` : 'unset',
        backgroundSize: '100% 280px',
        justifyContent: 'flex-start',
        ...mobileStyles,
      }}
    >
      <Box
        sx={{
          background: hasAddOn ? `url(${backgroundImg}) no-repeat` : 'unset',
          backgroundSize: isDesktop ? '100% 100%' : 'cover',
          pb: hasAddOn ? '20px' : 'unset',
          '.header-profile': {
            display: 'flex',
            alignItems: 'center',
          },
          '.mobile-search': {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
          },
        }}
      >
        <Box
          sx={{
            padding: '20px 40px',
            ...mobileHeaderStyles,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            pb={isDesktop && !isDashboard ? 3 : 0}
            borderBottom={
              isDesktop && !isDashboard
                ? '1.5px solid rgb(38 31 114 / 20%)'
                : 'unset'
            }
          >
            <div>
              {isDesktop ? (
                <SearchComponent isDesktop={isDesktop} isLoading={isLoading} />
              ) : (
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
              )}
            </div>
            <div className="header-profile">
              {isLoading ? (
                <IconSkeleton sx={{ mr: 2 }} />
              ) : (
                <NotificationBell />
              )}
              {getAvatar()}
            </div>
          </Box>
        </Box>
        {!isDesktop && (
          <div className="mobile-search">
            <SearchComponent isDesktop={isDesktop} isLoading={isLoading} />
          </div>
        )}
        {!hideTitle && (
          <Typography
            variant="h6"
            fontWeight={700}
            fontSize={24}
            p="0px 40px 20px 40px"
          >
            {activeRoute?.name ?? ''}
          </Typography>
        )}
        {hasAddOn && (
          <Box p={isDesktop ? '0px 40px' : '0px 20px'}>
            {isCustomer && <CustomerAddon />}
            {headerAddons && (headerAddons as ReactNode)}
          </Box>
        )}
      </Box>
      <Box
        flex={1}
        p={isDesktop ? '0px 40px 30px 40px' : '0px 20px 120px 20px'}
        // flex={1}
        pb={!isDesktop ? '120px' : '0px'}
        overflow={isDesktop ? 'auto' : 'unset'}
        height="100%"
      >
        {props.children}
      </Box>
    </Box>
  )
}

const SearchComponent = (props: any) => {
  const {
    setGlobalSearchVal,
    globalSearchVal = '',
    searchLabel,
    setFireSearch,
    isSearchHidden,
  } = useContext(SearchContext)

  const isLoading = props.isLoading

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(() => {
      setFireSearch(true)
      // do your search logic here
    }, 1000),
    []
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalSearchVal(event.target.value)
    debouncedSearch()
  }

  const mobileProps = !props.isDesktop
    ? {
        width: '100%',
        '.MuiInputBase-root': {
          svg: {
            mr: '10px',
          },
          input: {
            padding: '14px 0px',
          },
        },
      }
    : {}

  if (isSearchHidden) {
    return null
  }

  if (isLoading && !isSearchHidden) {
    return (
      <RectangularSkeleton
        sx={{ borderRadius: '10px' }}
        height="40px"
        width="330px"
      />
    )
  }

  return (
    <FormControl
      sx={{
        width: '335px',
        '.MuiOutlinedInput-root': {
          bgcolor: '#fff',
          borderRadius: '10px',
          svg: {
            mr: '5px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#fff',
            },
          },
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#fff',
            },
          },
        },
        ...mobileProps,
      }}
    >
      <TextField
        placeholder={searchLabel}
        size="small"
        id="outlined-basic"
        variant="outlined"
        onChange={handleChange}
        value={globalSearchVal}
        InputProps={{
          startAdornment: (
            <SearchIcon
              sx={{
                fontSize: '20px',
              }}
            />
          ),
        }}
      />
    </FormControl>
  )
}

export default ContentWrapper
