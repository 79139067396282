import { create } from 'zustand'

const useAccountStore = create<{
  accounts: any[]
  selectedAccount: any
  setAccounts: (accounts: any) => void
  setSelectedAccount: (account: any) => void
}>((set) => ({
  accounts: [],
  selectedAccount: null,
  setAccounts: (accounts: any) => set({ accounts }),
  setSelectedAccount: (selectedAccount: any) => set({ selectedAccount }),
}))

export default useAccountStore
