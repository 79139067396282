import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

export default function CustomDialog(props: any) {
  const {
    onClose,
    open,
    title = '',
    children,
    maxWidth = 'sm',
    showCloseIcon = true,
    isDesktop,
  } = props

  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      fullWidth
      sx={{
        '.MuiPaper-root': {
          overflow: 'unset',
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            position: 'relative',
            '.close-icon': {
              position: 'absolute',
              top: '-30px',
              right: '-34px',
              bgcolor: '#3791F0',
              height: '25px',
              width: '25px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: '#fff',
              cursor: 'pointer',
              svg: {
                fontSize: 20,
              },
            },
          }}
        >
          <Typography color="#261F72" fontSize={24} fontWeight={700}>
            {title}
          </Typography>
          {showCloseIcon && (
            <div className="close-icon">
              <CloseIcon onClick={onClose} />
            </div>
          )}
        </Box>
      </DialogTitle>
      <Box
        overflow="auto"
        p="0px 24px 24px 24px"
        mb={isDesktop ? 'unset' : '24px'}
      >
        {children && children}
      </Box>
    </Dialog>
  )
}
