import { Skeleton } from '@mui/material'

export default function RectangularSkeleton(props: any) {
    return (
        <Skeleton
            variant="rectangular"
            {...props}
            width={props.width || 0}
            height={props.height || 0}
        />
    )
}
