import { useContext } from 'react'
import {
  Avatar,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocation } from 'react-router'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import Logo from '../../assets/images/logo.png'
import routes from '../../routes'
import IconSkeleton from '../skeletons/icon.skeleton'
import TextSkeleton from '../skeletons/text.skeleton'
import { AppContext } from '../../Protected'
import { removeAuth } from '../../services/auth.service'

const NavBarWrapper = () => {
  const location = useLocation()
  const { isDesktop, navigate, userDetails, isLoading } = useContext(AppContext)
  const menu = routes.filter(
    (r: any) =>
      r.accessRoles &&
      r.accessRoles.some((item: string) =>
        (userDetails?.roles ?? ['admin']).includes(item)
      )
  )

  const mobileNavStyles = !isDesktop
    ? {
        position: 'fixed',
        bottom: 0,
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px 0px',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        bgcolor: '#fff',
        width: '100%',
        zIndex: 1,
        '.MuiList-root': {
          display: 'flex',
          '.MuiButtonBase-root': {
            textAlign: 'center',
            flexDirection: 'column',
            '.MuiListItemText-root span': {
              fontSize: '10px',
            },
            '.MuiAvatar-root': {
              height: 'unset',
            },
          },
        },
      }
    : {}

  return (
    <Box
      width={isDesktop ? 300 : 0}
      height={isDesktop ? '100vh' : 'unset'}
      position="relative"
      bgcolor="#fff"
      zIndex={2}
      boxShadow="0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 1px rgba(0,0,0,0.12)"
      sx={{
        '.logo': {
          textAlign: 'center',
          padding: '20px 0px',
        },
        '.navigations': {
          mt: '30px',
          ...mobileNavStyles,
        },
      }}
    >
      {isDesktop && (
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
      )}
      <div className="navigations">
        <List
          sx={{
            width: '100%',
            pointerEvents: isLoading ? 'none' : 'unset',
            '.MuiListItemButton-root': {
              '.MuiListItemIcon-root': {
                '.MuiAvatar-root': {
                  minWidth: '50px',
                  bgcolor: 'unset',
                  'svg path': {
                    stroke: '#A5A5A5',
                  },
                },
              },
              '.MuiListItemText-root': {
                '.MuiTypography-root': {
                  fontFamily: 'Gilroy Semibold',
                  color: '#A5A5A5',
                },
              },
              '&.active': {
                '.MuiListItemIcon-root': {
                  '.MuiAvatar-root': {
                    bgcolor: 'unset',
                    'svg path': {
                      stroke: '#FF4EA3',
                    },
                  },
                },
                '.MuiListItemText-root': {
                  '.MuiTypography-root': {
                    fontFamily: 'Gilroy Semibold',
                    color: '#261F72',
                  },
                },
                '&.fill-path': {
                  'svg path:last-child': {
                    fill: '#FF4EA3',
                  },
                },
              },
            },
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {menu.map((m: any, index: number) => {
            const isActive = location.pathname === m.path

            return (
              <ListItemButton
                key={`menu-${index}`}
                className={`${!isLoading && isActive ? 'active' : ''} ${
                  m.fillPath ? 'fill-path' : ''
                }`}
                onClick={() => navigate(m.path)}
              >
                <ListItemIcon>
                  <Avatar>{isLoading ? <IconSkeleton /> : m.icon}</Avatar>
                </ListItemIcon>
                {isLoading ? (
                  <TextSkeleton />
                ) : (
                  <ListItemText primary={m.name} />
                )}
              </ListItemButton>
            )
          })}
          {!isDesktop && !isLoading && (
            <ListItemButton
              onClick={() => {
                removeAuth()
                window.location.reload()
              }}
            >
              <ListItemIcon>
                <Avatar>
                  {' '}
                  {isLoading ? (
                    <IconSkeleton />
                  ) : (
                    <PlayArrowIcon sx={{ color: '#000' }} />
                  )}
                </Avatar>
              </ListItemIcon>
              {isLoading ? <TextSkeleton /> : <ListItemText primary="Logout" />}
            </ListItemButton>
          )}
        </List>
        {isDesktop && !isLoading && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              cursor: 'pointer',
              bottom: 0,
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
              },
              '.MuiListItemText-root .MuiTypography-root': {
                fontFamily: 'Gilroy Semibold',
              },
              '.logout': {
                display: 'flex',
                alignItems: 'center',
                padding: '12px 30px',
                borderTop: '2px solid #eee',
              },
            }}
          >
            <div
              className="logout"
              onClick={() => {
                removeAuth()
                window.location.reload()
              }}
            >
              {isLoading ? <TextSkeleton /> : <ListItemText primary="Logout" />}
              {isLoading ? (
                <IconSkeleton />
              ) : (
                <PlayArrowIcon sx={{ color: '#000' }} />
              )}
            </div>
          </Box>
        )}
      </div>
    </Box>
  )
}

export default NavBarWrapper
