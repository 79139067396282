import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios'
import { getToken } from './auth.service'
import endpoints, { requiresCSRFToken } from './endpoints'

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: process.env.NODE_ENV === 'development' ? 0 : 100000,
}

const API_INSTANCE: AxiosInstance = axios.create(config)

const fetchCSRFToken = async () =>
  await API_INSTANCE.get(endpoints.CSRF_TOKEN, {
    withCredentials: true,
  })

API_INSTANCE.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (
      !config.headers?.[config.xsrfHeaderName as string] &&
      requiresCSRFToken(config.url ?? '')
    ) {
      await fetchCSRFToken()
    }

    config.withCredentials = true

    return config
  }
)

// Request interceptor: add Bearer token
API_INSTANCE.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const accessToken = getToken()
    if (accessToken) {
      ;(
        config.headers as AxiosRequestHeaders
      ).Authorization = `Bearer ${accessToken}`
    }
    return config
  }
)

export default API_INSTANCE

export function objectToQueryParams(objectParam: any): string {
  let params = {}

  for (var key in objectParam) {
    if (objectParam[key]) {
      params = { ...params, ...{ [key]: objectParam[key] } }
    }
  }

  return new URLSearchParams(params).toString()
}
