import { Box, Collapse } from '@mui/material'

const CollapsibleRow = (props: any) => {
  const { row, open, collapsibleComponent } = props
  return (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      sx={{ borderTopLeftRadius: 2 }}
    >
      <Box
        sx={{
          margin: '0px',
          backgroundColor: '#4862FF',
          borderRadius: '5px 0px 5px 5px',
          color: '#fff',
        }}
      >
        {collapsibleComponent && collapsibleComponent(row)}
      </Box>
    </Collapse>
  )
}

export default CollapsibleRow
