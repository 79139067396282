import { Box, Button, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import CollapsibleRow from './collapsibleRow'
import OptionPopover from './optionPopover'
import CustomCheckbox from '../customCheckbox'

const RowWrapper = (props: any) => {
  const {
    row,
    collapsible,
    withOptions,
    columns,
    contextMenu,
    isContextMenuOpen,
    handleCloseContextMenu,
    handleOpenContextMenu,
    anchorEl,
    activeRow,
    index,
    count,
    alphabetFilterComponent,
    collapsibleComponent,
    isLoading,
  } = props
  const [open, setOpen] = useState(false)
  const colapsibleRowSpanCount =
    Object.keys(columns).length + 2 + (withOptions ? 1 : 0)

  return (
    <>
      <TableRow
        sx={{
          width: '100%',
          '& > *': { borderBottom: 'unset' },
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        }}
        className={index % 2 === 0 ? 'odd' : 'even'}
      >
        {/* <TableCell padding="checkbox">
          <CustomCheckbox checked={false} onChange={() => {}} sx={{ ml: 2 }} />
        </TableCell> */}
        {columns.map((column: any, i: number) => {
          const value = row[column.key]
          return (
            <TableCell key={`tci-${row.id}-${i}`} align={column.align}>
              {column.customValue ? column.customValue(row) : value}
            </TableCell>
          )
        })}

        {collapsible && (
          <TableCell align="right" sx={{ position: 'relative', width: '0px' }}>
            <Button
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              disabled={isLoading}
              sx={{
                backgroundColor: open ? '#4862FF' : '#f4f7ff',
                color: open ? '#fff' : '#4863ff',
                borderRadius: open ? '4px 4px 0 0' : '4px',
                minWidth: '45px',
                marginRight: '0px',
                '&:hover': {
                  backgroundColor: '#4862FF',
                  color: '#fff',
                },
              }}
            >
              {open ? (
                <>
                  <KeyboardArrowUpIcon />
                </>
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Button>
            {open && (
              <Box
                sx={{
                  backgroundColor: '#4862FF',
                  height: '20px',
                  position: 'absolute',
                  bottom: '0',
                  width: '45px',
                  right: '16px',
                }}
              />
            )}
          </TableCell>
        )}

        {withOptions && (
          <TableCell align="right" width={2}>
            <OptionPopover
              key={`context-option-popover-${row.id}`}
              id={`context-option-popover-${row.id}`}
              isContextMenuOpen={isContextMenuOpen}
              anchorEl={anchorEl}
              handleCloseContextMenu={handleCloseContextMenu}
              handleOpenContextMenu={handleOpenContextMenu}
              contextMenu={contextMenu}
              row={row}
              activeRow={activeRow}
              disabled={isLoading}
            />
          </TableCell>
        )}
        {/* {index === 0 && (
          <TableCell
            sx={{
              verticalAlign: 'baseline',
              width: 2,
              backgroundColor: 'transparent!important',
              pt: 0,
            }}
            rowSpan={count + 1}
          >
            {alphabetFilterComponent}
          </TableCell>
        )} */}
      </TableRow>
      {collapsible && (
        <TableRow
          sx={{
            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
          }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={colapsibleRowSpanCount}
            sx={{ backgroundColor: '#fff' }}
          >
            <CollapsibleRow
              row={row}
              open={open}
              collapsibleComponent={collapsibleComponent}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default RowWrapper
