/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import moment from 'moment'
import { useQueryClient } from 'react-query'
import { Box, Typography } from '@mui/material'

import { AppContext } from '../../Protected'
import Posts from './posts'
import { useGetAccountPosts } from '../../services/account.service'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import TextSkeleton from '../skeletons/text.skeleton'
import { formatDate } from '../../helpers/utils'
import AddSubscription from './customer-dashboard/add-subscription'
import SubscribedModal from './customer-dashboard/subscribed.modal'
import useAccountStore from '../../store/account.store'

const CustomerDashboard = () => {
  const queryClient = useQueryClient()
  const { isDesktop } = useContext(AppContext)
  const { selectedAccount } = useAccountStore()

  const [isSubscribedDialogOpen, setIsSubscribedDialogOpen] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)
  const subscriptionId = queryParams?.get('subscription')

  let selectedSubsciption = null

  if (subscriptionId) {
    selectedSubsciption = selectedAccount?.subscriptions?.find(
      (s: any) => s.id === +subscriptionId!
    )
  }

  const {
    data: posts = [],
    refetch: fetchPosts,
    isFetching: isFetchingPosts,
  } = useGetAccountPosts(selectedAccount?.id)

  useEffect(() => {
    if (selectedAccount?.id) {
      fetchPosts()
    }
  }, [selectedAccount]) // eslint-disable-line

  useEffect(() => {
    if (subscriptionId) {
      setIsSubscribedDialogOpen(true)
    }
  }, [subscriptionId])

  if (isFetchingPosts) {
    return Loaders()
  }

  const groupedPost = posts
    .map((post: any) => {
      return {
        ...post,
        dateLabel: formatDate(post.created_at),
      }
    })
    .reduce((acc: any, curr: any) => {
      const key = curr.dateLabel
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(curr)
      return acc
    }, {})

  const onToggleSubscribedModal = () => {
    if (isSubscribedDialogOpen) {
      queryClient.invalidateQueries({ queryKey: ['user-accounts'] })
    }

    setIsSubscribedDialogOpen(!isSubscribedDialogOpen)
  }

  if (!selectedAccount?.subscriptions?.length) {
    return (
      <AddSubscription
        account={selectedAccount}
        selectedSubsciption={selectedSubsciption}
      />
    )
  }

  const dateKeys = Object.keys(groupedPost)

  return (
    <Box
      height="100%"
      width="100%"
      alignItems="center"
      display="flex"
      justifyContent="center"
    >
      {dateKeys.length === 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
          mt={isDesktop ? 0 : 3}
        >
          <InstagramIcon sx={{ width: '100px', height: '100px' }} />
          <Typography
            mt={3}
            sx={{ color: '#261F72', fontSize: 20, fontWeight: '700' }}
          >
            No Posts found.
          </Typography>
        </Box>
      )}
      {dateKeys.map((label: string) => {
        const datePosts = groupedPost[label]
        let dateLabel = label

        if (formatDate() === label) {
          dateLabel = 'Today'
        }
        if (formatDate(moment().subtract(1, 'day').toISOString()) === label) {
          dateLabel = 'Yesterday'
        }
        return (
          <Posts
            key={label}
            account={selectedAccount}
            posts={datePosts}
            label={dateLabel}
            isDesktop={isDesktop}
          />
        )
      })}
      <SubscribedModal
        onToggle={onToggleSubscribedModal}
        isOpen={isSubscribedDialogOpen}
        selectedSubsciption={selectedSubsciption}
      />
    </Box>
  )
}

const Loaders = (key: string = 'loading') => {
  const { isDesktop } = useContext(AppContext)
  return (
    <Box overflow="hidden" width="100%">
      <Box display="flex" justifyContent="space-between">
        <TextSkeleton
          width="100px"
          height="40px"
          sx={{ m: '12px', mt: '20px' }}
        />
        <TextSkeleton
          width="300px"
          height="40px"
          sx={{ m: '12px', mt: '20px' }}
        />
      </Box>
      <Box display="flex">
        {Array(isDesktop ? 4 : 2)
          .fill(null)
          .map((_, i: number) => {
            return (
              <RectangularSkeleton
                key={key + i}
                height={isDesktop ? '280px' : '200px'}
                width={isDesktop ? '25%' : '50%'}
                sx={{
                  m: '12px',
                  borderRadius: '10px',
                }}
              />
            )
          })}
      </Box>
    </Box>
  )
}

export default CustomerDashboard
