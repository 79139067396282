import { useContext, useEffect } from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from 'chart.js'
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { Line } from 'react-chartjs-2'

import { AppContext } from '../../Protected'
import Statistics from './admin-dashboard/statistics'
import { useGetStatistics } from '../../services/dashboard.service'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'
import { getPercentage, roundToNearestHundred } from '../../helpers/utils'
import { DataTypes, PropTypes } from '../../types/dashboard.types'
import moment from 'moment'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const AdminDashboard = () => {
  const { isDesktop, getHeaderAddOns, setIsLoaderShown } =
    useContext(AppContext)
  const { data, isFetched, isLoading } = useGetStatistics()

  useEffect(() => {
    if (isFetched) {
      setIsLoaderShown(false)
      getHeaderAddOns(<HeaderAddon {...data} />)
    }
  }, [isFetched]) // eslint-disable-line

  const lineChartLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const getOverAllData = () => {
    const overAlldata = data?.overall?.data ?? []

    const dataByMonth: any = {}

    lineChartLabels.forEach((month) => {
      dataByMonth[month] = 0
    })

    overAlldata.forEach((item: DataTypes) => {
      const date = moment(item.date)
      const monthName = date.format('MMM')
      dataByMonth[monthName] += parseFloat(item.total)
    })

    return lineChartLabels.map((month) => dataByMonth[month])
  }

  let max = Math.max(...getOverAllData())
  max = max ? roundToNearestHundred(max) : 100

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max,
        ticks: {
          stepSize: max / 10,
        },
        border: {
          dash: [5, 5],
        },
      },
    },
    pointRadius: 0,
    filler: {
      propagate: false,
    },
    interaction: {
      intersect: false,
    },
  }

  const lineChartData = {
    labels: lineChartLabels,
    datasets: [
      {
        label: 'data',
        data: getOverAllData(),
        borderColor: '#d78fe0',
        lineTension: 0,
        radius: 7,
        backgroundColor: 'rgba(65, 105, 225, 0.12)',
        borderWidth: 2,
        fill: true,
      },
    ],
  }

  if (isLoading) {
    return (
      <Box
        position="relative"
        height={isDesktop ? '85%' : '200px'}
        py={isDesktop ? '40px' : '20px'}
      >
        <RectangularSkeleton width="100%" height="100%" />
        <CircularProgress
          sx={{ position: 'absolute', top: '49%', left: '49%' }}
        />
      </Box>
    )
  }

  return (
    <Box pt={isDesktop ? '30px' : '20px'} pb={isDesktop ? '30px' : '20px'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              p: '15px',
              borderRadius: '10px',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Typography color="#1B2767" fontSize={14} fontWeight={700}>
                Overall Services Delivered
              </Typography>
            </Stack>
            <Stack sx={{ mt: 4 }}>
              <Line options={lineChartOptions} data={lineChartData} />
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

const HeaderAddon = (props: PropTypes) => {
  const { daily, weekly, monthly, yearly } = props

  const salesStatistics = [
    {
      title: 'Daily Sales',
      amount: daily?.total ?? 0,
      percentage: getPercentage(daily),
      label: 'since yesterday',
    },
    {
      title: 'Weekly Sales',
      amount: weekly?.total ?? 0,
      percentage: getPercentage(weekly, 'week'),
      label: 'since last week',
    },
    {
      title: 'Monthly Sales',
      amount: monthly?.total ?? 0,
      percentage: getPercentage(monthly, 'month'),
      label: 'since last month',
    },
    {
      title: 'Yearly Sales',
      amount: yearly?.total ?? 0,
      percentage: getPercentage(yearly, 'year'),
      label: 'since last year',
    },
  ]
  return (
    <>
      {/* <Box
        sx={{
          display: 'block',
          float: 'right',
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <Typography
            color="#261F72"
            fontSize={14}
            fontWeight={400}
            sx={{ mr: 2 }}
          >
            Sort By:
          </Typography>
          <FormControl size="small">
            <Select
              value={'comments'}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  borderStyle: 'none',
                },
                bgcolor: '#ffdcf3',
                borderRadius: '10px',
                '.MuiSelect-outlined': {
                  padding: '8px 14px',
                },
              }}
            >
              <MenuItem value={'comments'}>Comments</MenuItem>
              <MenuItem value={'likes'}>Likes</MenuItem>
              <MenuItem value={'followers'}>Followers</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box> */}
      <Grid container spacing={2}>
        {salesStatistics.map((statistics: any, index: number) => {
          return <Statistics statistics={statistics} key={`stats-${index}`} />
        })}
      </Grid>
    </>
  )
}

export default AdminDashboard
