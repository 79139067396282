import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'
import TableHeader from './tableHeader'
import { useState } from 'react'
import OptionPopover from './optionPopover'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CollapsibleRow from './collapsibleRow'
import TextSkeleton from '../skeletons/text.skeleton'

const MobileTable = (props: any) => {
  const {
    rows,
    collapsible = false,
    withOptions = false,
    columns,
    contextMenu = [],
    title = '',
    mobileHeader = null,
    excludedHeaders = [],
    collapsibleComponent,
    aditionalFilters,
    total,
    isLoading,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeRow, setActiveRow] = useState<any | null>(null)
  const [open, setOpen] = useState(-1)

  const isContextMenuOpen = Boolean(anchorEl)

  const handleOpenContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setActiveRow(row)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseContextMenu = () => {
    setAnchorEl(null)
  }

  let tableData = rows
  let tableColumns = columns
  if (isLoading) {
    const loadingData = Object.keys(columns).reduce(
      (acc, curr) => ({ ...acc, [curr]: '' }),
      {}
    )
    tableData = Array(2)
      .fill(null)
      .map(() => loadingData)

    tableColumns = tableColumns.map((col: any) => {
      return { ...col, customValue: () => <TextSkeleton width="100px" /> }
    })
  }

  return (
    <Box>
      <Box>
        <TableHeader
          title={title}
          aditionalFilters={aditionalFilters}
          total={total}
          isLoading={isLoading}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        {tableData.map((row: any, index: number) => (
          <Card sx={{ mb: 2, borderRadius: '10px' }} key={`rw-${index}`}>
            <CardHeader
              avatar={mobileHeader ? mobileHeader(row) : null}
              action={
                <>
                  {withOptions && (
                    <OptionPopover
                      id={`context-menu-button-${row.id}`}
                      isContextMenuOpen={isContextMenuOpen}
                      anchorEl={anchorEl}
                      handleCloseContextMenu={handleCloseContextMenu}
                      handleOpenContextMenu={handleOpenContextMenu}
                      contextMenu={contextMenu}
                      row={row}
                      activeRow={activeRow}
                      disabled={isLoading}
                    />
                  )}
                  {collapsible && (
                    <Box sx={{ position: 'relative' }}>
                      <Button
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(open === index ? -1 : index)}
                        sx={{
                          backgroundColor:
                            open === index ? '#4862FF' : '#f4f7ff',
                          color: open === index ? '#fff' : '#4863ff',
                          borderRadius: open === index ? '4px 4px 0 0' : '4px',
                          minWidth: '45px',
                          marginRight: '0px',
                          '&:hover': {
                            backgroundColor: '#4862FF',
                            color: '#fff',
                          },
                        }}
                      >
                        {open === index ? (
                          <>
                            <KeyboardArrowUpIcon />
                          </>
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </Button>
                      {open === index && (
                        <Box
                          sx={{
                            backgroundColor: '#4862FF',
                            height: '33px',
                            position: 'absolute',
                            bottom: '-33px',
                            width: '45px',
                            right: '0px',
                          }}
                        ></Box>
                      )}
                    </Box>
                  )}
                </>
              }
            />

            {collapsible && (
              <Box sx={{ padding: '0 8px' }}>
                <CollapsibleRow
                  row={row}
                  open={open === index}
                  collapsibleComponent={collapsibleComponent}
                />
              </Box>
            )}

            <CardContent sx={{ borderTop: '1px solid #EAEAEA' }}>
              {tableColumns
                .filter((column: any) => !excludedHeaders.includes(column.key))
                .map((column: any, i: number) => {
                  const value = row[column.key]
                  return (
                    <Box
                      key={`col-${i}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1.5,
                      }}
                    >
                      <Typography
                        component="h4"
                        sx={{
                          color: '#261F72',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '20px',
                        }}
                      >
                        {column.label}
                      </Typography>
                      <Box
                        sx={{
                          color: '#261F72',
                          fontSize: '15px',
                          fontWeight: '600',
                          lineHeight: '20px',
                        }}
                      >
                        {column.customValue ? column.customValue(row) : value}
                      </Box>
                    </Box>
                  )
                })}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default MobileTable
