import { useMutation, useQuery } from 'react-query'
import API_INSTANCE from './api'
import endpoints from './endpoints'
import { format } from 'react-string-format'
import { AccountCheckoutProps, AccountPostProps } from '../types/account.types'
import { apiResponseSimplify } from '../helpers/utils'

/**
 * Update User Info
 */
export const useUpdateUserMutation = () => {
  return useMutation(
    (request: {
      id: number
      name?: string
      email?: string
      password?: string
      current_password?: string
      phone_number?: string
    }) => API_INSTANCE.put(format(endpoints.UPDATE_USER, request.id), request)
  )
}

/**
 * Update User Status
 */
export const useUpdateUserStatusMutation = () => {
  return useMutation((request: { id: string | number; action: string }) =>
    API_INSTANCE.put(
      format(endpoints.UPDATE_USER_STATUS, request.id, request.action),
      request
    )
  )
}

/**
 * Scrape Account Posts
 */
export const useScrapeAccountPosts = (account: number) => {
  return useMutation((request: { count: number; max_id: string }) =>
    API_INSTANCE.post(format(endpoints.SCRAPE_ACCOUNT_POSTS, account), request)
  )
}

/**
 * Add Account Post
 */
export const useAddAccountPost = (account: number) => {
  return useMutation((request: AccountPostProps) =>
    API_INSTANCE.post(format(endpoints.ACCOUNT_POST, account), request)
  )
}

/**
 * Get Account Posts
 */
export const useGetAccountPosts = (accountId: number) => {
  return useQuery(
    ['posts-list', accountId],
    () => API_INSTANCE.get(format(endpoints.GET_ACCOUNT_POSTS, accountId)),
    {
      enabled: false,
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )
}

export const useAddAccountProfile = () => {
  return useMutation((request: { user_name: string }) =>
    API_INSTANCE.post(endpoints.ADD_ACCOUNT_PROFILE, request)
  )
}

/**
 *  Account Checkout
 */
export const useAccountCheckout = (account: number) => {
  return useMutation((request: AccountCheckoutProps) =>
    API_INSTANCE.post(format(endpoints.ADD_SUBSCRIPTION, account), request)
  )
}
