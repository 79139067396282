import { useQuery } from 'react-query'
import { apiResponseSimplify } from '../helpers/utils'
import API_INSTANCE from './api'
import endpoints from './endpoints'

export const useGetStatistics = () => {
  return useQuery(
    ['admin-dashboard-statistics'],
    () => API_INSTANCE.get(`${endpoints.GET_STATISTICS}`),
    {
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )
}
