import { useContext } from 'react'
import Box from '@mui/material/Box/Box'
import CustomerDashboard from '../components/dashboard/customer-dashboard'
import AdminDashboard from '../components/dashboard/admin-dashboard'
import { AppContext } from '../Protected'
import RectangularSkeleton from '../components/skeletons/rectangular.skeleton'
import { CircularProgress } from '@mui/material'

const Dashboard = () => {
  const { isDesktop, isAdmin, isLoading } = useContext(AppContext)

  return (
    <Box flexDirection="column" height="100%">
      {isLoading ? (
        <Box
          position="relative"
          pb={!isDesktop ? '120px' : '0px'}
          height={isDesktop ? '85%' : '200px'}
          py={isDesktop ? '40px' : '20px'}
        >
          <RectangularSkeleton width="100%" height="100%" />
          <CircularProgress
            sx={{ position: 'absolute', top: '49%', left: '49%' }}
          />
        </Box>
      ) : (
        <>{isAdmin ? <AdminDashboard /> : <CustomerDashboard />}</>
      )}
    </Box>
  )
}

export default Dashboard
