import { Box } from '@mui/material'

const AlphabetFilter = (props: any) => {
  const { handleAlphaFilter } = props
  const alpha = Array.from(Array(26)).map((e, i) => i + 65)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        right: 15,
      }}
    >
      {alpha.map((letter: any, i: number) => (
        <Box
          key={`fl-${i}`}
          sx={{
            mb: 0.3,
            cursor: 'pointer',
            color: '#261F72',
            fontSize: '16px',
            fontWeight: '400',
            '&:hover': {
              color: '#ff4ea3',
            },
          }}
          onClick={() => handleAlphaFilter(String.fromCharCode(letter))}
        >
          {String.fromCharCode(letter)}
        </Box>
      ))}
    </Box>
  )
}

export default AlphabetFilter
