import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Typography,
} from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { TransitionProps } from '@mui/material/transitions'

import CustomInput from '../../customInput'
import TextSkeleton from '../../skeletons/text.skeleton'
import {
  PackageType,
  SubscriptionStatus,
  pricing,
  serviceQualities,
} from '../../../types/subscription.types'
import CustomSelect from '../../customSelect'
import CustomSwitch from '../../customSwitch'
import IconSkeleton from '../../skeletons/icon.skeleton'
import { useGetFile } from '../../../services/file.service'
import {
  useDeleteSubscription,
  useUpdateSubscriptionStatus,
} from '../../../services/subscription.service'
import { AppContext } from '../../../Protected'
import CustomButton from '../../customButton'
import PicturePlaceholder from '../../../assets/images/avatars/pp-placeholder.png'

const formatService = (serviceKey: string) => {
  return serviceKey.replaceAll('_', ' ')
}

const getServiceName = (serviceKey: string) => {
  if (!serviceKey) return ''

  const service = serviceKey.split('_')
  return service.length ? service[service.length - 1] : ''
}

const getSubscriptionStatusValue = (status: any) => {
  if (!status) return SubscriptionStatus.paused

  return SubscriptionStatus.active
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SubscriptionAccount = (props: any) => {
  const [isEdit] = useState(false)
  const { isDesktop, subscription, refetch } = props
  const [billingDate, setBillingDate] = useState('')
  const [status, setStatus] = useState(false)
  const { setNotification } = useContext(AppContext)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<
    number | null
  >(null)

  const { isLoading, mutate: updateSubscription } =
    useUpdateSubscriptionStatus()
  const { mutate: deleteSubscription } = useDeleteSubscription()
  const { data: file, refetch: getFile } = useGetFile(
    subscription?.account?.photo
  )

  useEffect(() => {
    if (subscription) {
      const billDate = moment(subscription.ends_at).format(
        '[Every] Do [of the Month]'
      )

      const subStatus =
        subscription.status === SubscriptionStatus.active ? true : false

      setStatus(subStatus)
      setBillingDate(billDate)
    }
  }, [subscription])

  useEffect(() => {
    if (subscription?.account?.photo) {
      getFile()
    }
  }, [subscription?.account?.photo]) // eslint-disable-line

  // const handleEdit = () => {
  //   setIsEdit(!isEdit)
  // }

  const handleDelete = (subscriptionId: number) => {
    setOpenDeleteModal(true)
    setSubscriptionToDelete(subscriptionId)
  }

  const handleConfirmDelete = () => {
    deleteSubscription(subscriptionToDelete ?? 0, {
      onSuccess: () => {
        setOpenDeleteModal(false)
        refetch()
        setNotification('Successfully deleted subscription.')
      },
    })
  }

  const handleSubscriptionStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault()

    if (!subscription?.id) return

    updateSubscription(
      {
        id: subscription.id,
        status: getSubscriptionStatusValue(event.target.checked),
      },
      {
        onSuccess: () => {
          setNotification('Successfully updated subscription status.')
          refetch()
        },
      }
    )
  }

  return (
    <Card
      sx={{
        p: '15px',
        borderRadius: '10px',
        mb: 3,
        boxShadow:
          '0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 3px 1px rgba(0,0,0,0.12)',
      }}
    >
      <Box color="#51459E">
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            width={isDesktop ? '40%' : '100%'}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {isLoading ? (
                <IconSkeleton sx={{ mr: 3 }} size="54px" />
              ) : (
                <Box
                  borderRadius="50%"
                  padding={isDesktop ? '27px' : '54px'}
                  mr={3}
                  sx={{
                    background: `url(${file ?? PicturePlaceholder})`,
                    backgroundSize: 'cover',
                  }}
                />
              )}
              <Stack>
                <CustomInput
                  isLoading={isLoading}
                  isEdit={isEdit}
                  label=" User name"
                  value={subscription?.account?.user_name}
                  color="#51459E"
                  parentSx={{
                    padding: '10px 15px',
                  }}
                />
                {!isDesktop && (
                  <Stack justifyContent="center" pt="10px">
                    {isLoading ? (
                      <TextSkeleton height="30px" width="100px" />
                    ) : (
                      <Typography fontSize={14} color="rgb(0 0 0 / 50%)">
                        Billing Date
                      </Typography>
                    )}
                    {isLoading ? (
                      <TextSkeleton height="30px" width="200px" />
                    ) : (
                      <Typography fontSize={16} fontWeight={600}>
                        {billingDate}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isDesktop && (
              <Stack justifyContent="center" pl="10px">
                {isLoading ? (
                  <TextSkeleton height="30px" width="100px" />
                ) : (
                  <Typography fontSize={14} color="rgb(0 0 0 / 50%)">
                    Biling Date
                  </Typography>
                )}
                {isLoading ? (
                  <TextSkeleton height="30px" width="200px" />
                ) : (
                  <Typography fontSize={16} fontWeight={600}>
                    {billingDate}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          <Stack
            direction={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
          >
            {subscription.items &&
              subscription.items.length &&
              subscription.items.map((item: any, i: number) => {
                let packages = (pricing as any)[
                  getServiceName(item?.package?.service)
                ]['high-quality']
                packages = (packages ?? []).reduce(
                  (acc: any, curr: PackageType) => {
                    return {
                      ...acc,
                      [`${curr.id}`]: `${curr.count} ${curr.title}`,
                    }
                  },
                  {}
                )

                return (
                  <Stack
                    key={`subscription-${i}`}
                    justifyContent="center"
                    ml={isDesktop ? 2 : 0}
                    mt={isDesktop ? 0 : 2}
                    p="15px 20px"
                    borderRadius="10px"
                    bgcolor="#F5F4FF"
                    minWidth="151px"
                  >
                    {isLoading ? (
                      <TextSkeleton height="20px" width="100px" />
                    ) : (
                      <Typography mb="5px" fontSize={10}>
                        Subscription on
                      </Typography>
                    )}
                    {isLoading ? (
                      <TextSkeleton height="30px" width="150px" />
                    ) : isEdit ? (
                      <CustomSelect
                        label={`Type of ${getServiceName(
                          item?.package?.service
                        )}`}
                        value={item?.package?.service}
                        options={serviceQualities}
                        onChange={() => {}}
                      />
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          mb="5px"
                          fontSize={14}
                          fontWeight={600}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {formatService(item?.package?.service)}
                        </Typography>
                        {!isDesktop && (
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            sx={{
                              textTransform: 'capitalize',
                              span: {
                                fontWeight: '800!important',
                                fontSize: 18,
                              },
                            }}
                          >
                            <span>{item?.package?.quantity ?? ''}</span>{' '}
                            {getServiceName(item?.package?.service)}
                          </Typography>
                        )}
                      </Stack>
                    )}
                    {isLoading ? (
                      <TextSkeleton height="30px" width="100px" />
                    ) : isEdit ? (
                      <CustomSelect
                        label="Amount"
                        value={item?.package?.quantity}
                        options={packages}
                        onChange={() => {}}
                      />
                    ) : (
                      isDesktop && (
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          sx={{
                            textTransform: 'capitalize',
                            span: { fontWeight: '800!important', fontSize: 18 },
                          }}
                        >
                          <span>{item?.package?.quantity ?? ''}</span>{' '}
                          {getServiceName(item?.package?.service)}
                        </Typography>
                      )
                    )}
                  </Stack>
                )
              })}
          </Stack>
        </Stack>
        <Stack
          mt="20px"
          p="10px 0px 0px"
          borderTop="0.5px solid rgb(0 0 0 / 10%)"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {isLoading ? (
            <TextSkeleton height="30px" width="150px" />
          ) : (
            <CustomSwitch
              checked={status}
              onChange={handleSubscriptionStatusChange}
              label={`${props.enabled ? 'Disable' : 'Enable'} Subscription`}
            />
          )}
          <Stack direction="row" alignItems="center">
            {isLoading ? (
              <>
                <TextSkeleton height="30px" width="30px" sx={{ mr: 2 }} />
                {/* <TextSkeleton height="50px" width="60px" /> */}
              </>
            ) : (
              <>
                {(subscription?.status === SubscriptionStatus.cancelled ||
                  subscription?.status === SubscriptionStatus.expired) && (
                  <DeleteForeverIcon
                    sx={{ color: '#000', cursor: 'pointer' }}
                    onClick={() => handleDelete(subscription?.id)}
                  />
                )}
                {/* <Button
                  variant={isEdit ? 'contained' : 'outlined'}
                  color="success"
                  sx={{
                    ml: '10px',
                    p: '5px 10px 0px',
                    border: '1px solid #00B67A',
                    color: isEdit ? '#fff' : '#00B67A',
                    bgcolor: isEdit ? '#00B67A' : 'unset',
                    '&:hover': {
                      bgcolor: isEdit ? '#00B67A' : '',
                    },
                  }}
                  onClick={handleEdit}
                >
                  {isEdit ? 'Save' : 'Edit'}
                </Button> */}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
      <Dialog
        open={openDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDeleteModal(false)}
        disableEscapeKeyDown
        aria-describedby="add-coupon-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Delete Subscription Confirmation
        </DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to delete subscription</Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: isDesktop ? 'row-reverse' : 'column',
            pl: 2,
            pr: 2,
          }}
        >
          <CustomButton
            label="Confirm"
            sx={{
              p: '10px 15px!important',
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            onClick={handleConfirmDelete}
            isLoading={isLoading}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={() => setOpenDeleteModal(false)}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default SubscriptionAccount
