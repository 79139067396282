import { useMutation } from 'react-query'
import API_INSTANCE from './api'
import endpoints from './endpoints'
import { format } from 'react-string-format'

/**
 * Update Subscription Status
 */
export const useUpdateSubscriptionStatus = () => {
  return useMutation((request: { id: string | number; status: string }) =>
    API_INSTANCE.put(format(endpoints.UPDATE_SUBSCRIPTION, request.id), request)
  )
}

export const useUpdateAccountLikesProperties = () => {
  return useMutation((request: { id: string | number; payload: any }) =>
    API_INSTANCE.put(
      format(endpoints.UPDATE_ACCOUNT_LIKES_PROPERITES, request.id),
      request.payload
    )
  )
}

export const useDeleteSubscription = () => {
  return useMutation((id: number) =>
    API_INSTANCE.delete(format(endpoints.UPDATE_SUBSCRIPTION, id ?? ''))
  )
}
