import { useState, ChangeEvent } from 'react'
import {
  Box,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CheckScreenSize } from '../../../helpers/utils'
import { ErrorRounded } from '@mui/icons-material'
import CustomButton from '../../customButton'

const ChangePassWord = (props: any) => {
  const { data, onChange, onSubmit, isLoading } = props

  const isDesktop = CheckScreenSize('(min-width: 1200px)')

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [currentPasswordHasError, setCurrentPasswordHasError] = useState(false)
  const [passwordErrors, setPasswordErrors] = useState({
    capital: true,
    lowercase: true,
    number: true,
    characters: true,
  })
  const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false)

  const handleCurrentPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setCurrentPasswordHasError(!value)
    onChange('current_password', value)(event)
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPasswordErrors({
      capital: !value || !/[A-Z]/.test(value),
      lowercase: !value || !/[a-z]/.test(value),
      number: !value || !/\d/.test(value),
      characters: !value || value.length < 8,
    })
    setConfirmPasswordHasError(data.confirm_password !== value)
    onChange('password', value)(event)
  }

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setConfirmPasswordHasError(data.password !== value)
    onChange('confirm_password', value)(event)
  }

  const passwordHasError =
    Object.values(passwordErrors).filter((e) => e).length > 0

  const hasError =
    passwordHasError || confirmPasswordHasError || currentPasswordHasError

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      sx={{
        position: 'relative',
        height: '100%',
        '& h3': {
          m: 1,
          mb: 2,
        },
        hr: {
          opacity: '0.2',
          bordeColor: '#000000',
          m: 1,
        },
        '& img': {
          position: 'absolute',
          right: '10px',
          top: '0px',
        },
        '.MuiInputBase-root': {
          bgcolor: '#fcfcfc',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
        '& input': {
          fontSize: '14px',
        },
      }}
    >
      <h3>Update Password </h3>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, flexGrow: '1' }}
        >
          <InputLabel htmlFor="component-filled">Current Password</InputLabel>
          <FilledInput
            disableUnderline={true}
            id="current_password"
            type={showCurrentPassword ? 'text' : 'password'}
            onChange={handleCurrentPasswordChange}
            required
            error={currentPasswordHasError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  aria-label="toggle current password visibility"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  edge="end"
                  data-testid="current-password-visibility"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <hr />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, flexGrow: '1' }}
        >
          <InputLabel htmlFor="component-filled">New Password</InputLabel>
          <FilledInput
            type={showPassword ? 'text' : 'password'}
            disableUnderline={true}
            id="new_password"
            onChange={handlePasswordChange}
            required
            endAdornment={
              <InputAdornment position="end">
                {data.password &&
                  (passwordHasError ? (
                    <ErrorRounded
                      sx={{
                        fontSize: '20px',
                        color: 'red',
                      }}
                    />
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        fontSize: '20px',
                        color: '#00B67A',
                      }}
                    />
                  ))}
                <IconButton
                  disableRipple
                  aria-label="toggle new password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  data-testid="new-password-visibility"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {data.password && (
          <Box
            sx={{
              m: 1,
              flexGrow: '1',
              display: isDesktop ? 'flex' : 'block',
              '& div': {
                flexGrow: '1',
              },
              '& p': {
                color: '#00B67A',
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  marginRight: '10px',
                },
                '&.error': {
                  color: 'red',
                },
              },
            }}
          >
            <div>
              <p className={passwordErrors.capital ? 'error' : ''}>
                <CheckCircleIcon /> At least 1 capital letter
              </p>
              <p className={passwordErrors.lowercase ? 'error' : ''}>
                <CheckCircleIcon /> At least 1 lowercase letter
              </p>
            </div>
            <div>
              <p className={passwordErrors.number ? 'error' : ''}>
                <CheckCircleIcon /> At least 1 number
              </p>
              <p className={passwordErrors.characters ? 'error' : ''}>
                <CheckCircleIcon /> At least 8 characters
              </p>
            </div>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, flexGrow: '1' }}
        >
          <InputLabel htmlFor="component-filled">
            Confirm New Password
          </InputLabel>
          <FilledInput
            disableUnderline={true}
            id="confirm_password"
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={handleConfirmPasswordChange}
            required
            endAdornment={
              <InputAdornment position="end">
                {data.confirm_password &&
                  (confirmPasswordHasError ? (
                    <ErrorRounded
                      sx={{
                        fontSize: '20px',
                        color: 'red',
                      }}
                    />
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        fontSize: '20px',
                        color: '#00B67A',
                      }}
                    />
                  ))}
                <IconButton
                  disableRipple
                  aria-label="toggle new password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                  data-testid="new-password-visibility"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>

      <Box sx={{ height: isDesktop ? '80px' : 'auto' }}></Box>
      <Box
        sx={{
          textAlign: 'right',
          borderTop: '0.5px solid #ebebeb',
          marginTop: isDesktop ? 'auto' : '30px',
          padding: '25px 0',
        }}
      >
        <CustomButton
          isLoading={isLoading}
          type="submit"
          disableElevation
          label=" Update Password"
          className="likester-button"
          disabled={hasError}
        />
      </Box>
    </Box>
  )
}

export default ChangePassWord
