import { useMediaQuery } from '@mui/material'
import { AxiosError } from 'axios'
import endpoints from '../services/endpoints'
import moment from 'moment'
import { capitalize } from 'lodash'
import { DataTypes, EntryTypes } from '../types/dashboard.types'

export function CheckScreenSize(media: string = '(min-width: 1200px)') {
  return useMediaQuery(media)
}

type ErrorProps = {
  [key: string]: any
  message: string
  errors: object
}

export function getErrorMessage(err: AxiosError) {
  const code = err?.response?.status ?? 403
  const responseData = err?.response?.data as ErrorProps
  const message = responseData?.message ?? err?.message ?? ''
  const url = err?.config?.url
  switch (code) {
    case 403:
      if (url === endpoints.LOGIN) {
        return 'Unable to login using these credentials.'
      }
      return 'Request unauthorized.'
    default:
      return message
  }
}

export function apiResponseSimplify(apiResponse: any, isList = false) {
  if (isList && apiResponse?.data) {
    return apiResponse.data
  }

  if (apiResponse?.data?.data) {
    return apiResponse.data.data
  }
  return apiResponse
}

export function fixUrl(sourcePath: string = '', path: string = '/') {
  return `${sourcePath.replace(/\/$/, '')}/${path.replace(/^\//, '')}`
}

export function numValChecker(value: number, def: number = 0) {
  return value ?? def
}

export function isNumber(value: string | number) {
  const pattern = /^[0-9]+$/
  return pattern.test(`${value}`)
}

export function isNumOrDecimal(value: string) {
  return /^\d+(\.\d{0,2})?$/.test(value)
}

export function formatInstaNumber(n: number) {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export function formatDate(
  dateStr: string = '',
  format: string = 'MMMM D, YYYY'
) {
  return (dateStr ? moment(dateStr) : moment()).format(format)
}

export function getServiceTypeLabel(service: string) {
  if (!service) return ''

  let st = 'comments'
  if (service?.includes('likes')) {
    st = 'likes'
  }
  if (service?.includes('views')) {
    st = 'views'
  }
  return capitalize(st)
}

export function capitalizeUnderlinedLabel(label: string) {
  if (!label) return label

  return label
    .split('_')
    .map((s: string) => capitalize(s))
    .join(' ')
}

export function getPercentage(
  data: EntryTypes | undefined,
  rangeType: 'day' | 'week' | 'month' | 'year' = 'day'
): string {
  const prevStartDate = moment().subtract(1, rangeType).startOf(rangeType)
  const prevEndDate = moment().subtract(1, rangeType).endOf(rangeType)
  const currentStartDate = moment().startOf(rangeType)
  const currentEndDate = moment().endOf(rangeType)

  let format = 'YYYY-MM-DD'

  if (rangeType === 'month') {
    format = 'YYYY-MM'
  }

  if (rangeType === 'year') {
    format = 'YYYY'
  }

  const currentTotal = calculateTotalDateRange(
    data?.data ?? [],
    currentStartDate,
    currentEndDate,
    format,
    rangeType === 'week'
  )

  // Returns 0% if no sales for this week
  if (currentTotal === 0) return '0%'

  const previousTotal = calculateTotalDateRange(
    data?.data ?? [],
    prevStartDate,
    prevEndDate,
    format,
    rangeType === 'week'
  )

  if (previousTotal === 0) return '+100%'

  const total = ((currentTotal - previousTotal) / Math.abs(previousTotal)) * 100

  return `${total > 0 ? '+' : ''}${total.toFixed(2)}%`
}

function calculateTotalDateRange(
  data: DataTypes[],
  startDate: moment.Moment,
  endDate: moment.Moment,
  format: string,
  isWeek: boolean
): number {
  const filteredData = data.filter((entry) => {
    if (!isWeek) {
      const entryDate = moment(entry.date, format)
      return entryDate.isBetween(startDate, endDate, 'days', '[]')
    } else {
      const { week = 0, year = 0 } = entry ?? {}
      const startWeek = startDate.week()
      const startYear = startDate.year()
      const endWeek = endDate.week()
      const endYear = endDate.year()

      return (
        week >= startWeek &&
        week <= endWeek &&
        year >= startYear &&
        year <= endYear
      )
    }
  })

  const total = filteredData.reduce((accumulator, entry) => {
    return accumulator + parseFloat(entry.total)
  }, 0)

  return total
}

export function roundToNearestHundred(num: number) {
  return Math.ceil(num / 100) * 100
}

export function getServerUnauthorizedError(error: any) {
  return Object.values(error?.response?.data)[0] ?? error.message
}
