import { useQuery, useQueryClient } from 'react-query'
import API_INSTANCE from '../services/api'
import endpoints from '../services/endpoints'
import { apiResponseSimplify } from '../helpers/utils'

/**
 * Get Options
 * type(optional): key of the option
 */
export const useOptions = (key: string = '') => {
  const existingOptions = useQueryClient().getQueryData('options') as any
  let enabled = true
  if (existingOptions?.data?.data) {
    enabled = false
  }

  const { data, refetch, isLoading } = useQuery(
    ['options'],
    () => API_INSTANCE.get(endpoints.OPTIONS),
    {
      enabled,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )

  let options = data

  if (key) {
    options = data[key] ?? []
  }

  return {
    options,
    refetch,
    isLoading,
  }
}
