import { createContext, useState } from 'react'

interface SearchProps {
  globalSearchVal: string
  setGlobalSearchVal: Function
  searchLabel: string
  setSearchLabel: Function
  fireSearch: boolean
  setFireSearch: Function
  hideSearch: Function
  isSearchHidden: boolean
}

export const SearchContext = createContext({} as SearchProps)

export default function SearchContextProvider({ children }: any) {
  const [globalSearchVal, setGlobalSearchVal] = useState('')
  const [isSearchHidden, hideSearch] = useState(false)
  const [searchLabel, setSearchLabel] = useState(
    'Search contact, group or project'
  )
  const [fireSearch, setFireSearch] = useState(false)

  return (
    <SearchContext.Provider
      value={{
        globalSearchVal,
        setGlobalSearchVal,
        searchLabel,
        setSearchLabel,
        fireSearch,
        setFireSearch,
        hideSearch,
        isSearchHidden,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
