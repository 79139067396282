import { Box, Typography } from '@mui/material'
import TextSkeleton from './skeletons/text.skeleton'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

export default function CustomDate(props: any) {
  const {
    isLoading,
    isEdit = true,
    label = 'Label',
    parentSx = {},
    skeletonProps = { width: '100px', height: '30px' },
    ...rest
  } = props
  return (
    <Box
      sx={
        isEdit
          ? {
              borderRadius: '10px',
              padding: '4px 10px',
              border: '1px solid #D9D9D9',
              ...parentSx,
            }
          : {}
      }
    >
      {isLoading ? (
        <TextSkeleton {...skeletonProps} />
      ) : (
        <Typography fontSize={14} color="rgb(0 0 0 / 50%)">
          {label}
        </Typography>
      )}
      {isLoading ? (
        <TextSkeleton {...skeletonProps} />
      ) : isEdit ? (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            input: {
              fontSize: 16,
              fontWeight: 600,
              border: 'none',
              outline: 'none',
              width: '90%',
            },
            svg: {
              width: '25px',
              height: '25px',
              color: '#ccc',
            },
            '.react-datepicker__month-container': {
              backgroundColor: '#fff',
              borderRadius: '10px',
              borderColor: '#ccc',
            },
            '.react-datepicker': {
              backgroundColor: '#f6fbff',
              border: '1px solid #dae1e7',
            },
            '.react-datepicker__header': {
              borderColor: 'transparent',
              backgroundColor: '#f6fbff',
            },
            '.react-datepicker-popper[data-placement^=bottom]': {
              pt: '17px',
            },
          }}
        >
          <DatePicker {...rest} />
          <CalendarMonthOutlinedIcon />
        </Box>
      ) : (
        <Typography fontSize={16} fontWeight={600}>
          {rest?.selected ?? ''}
        </Typography>
      )}
    </Box>
  )
}
