import { Box, TextField, Typography } from '@mui/material'
import TextSkeleton from './skeletons/text.skeleton'

export default function CustomInput(props: any) {
  const {
    isLoading,
    isEdit = true,
    label = 'Label',
    value,
    parentSx = {},
    skeletonProps = { width: '100px', height: '30px' },
    color = '#000',
    placeholder = '',
    ...rest
  } = props
  return (
    <Box
      sx={
        isEdit
          ? {
              borderRadius: '10px',
              padding: '5px 10px',
              border: '1px solid #D9D9D9',
              ...parentSx,
              ...(rest.error ? { border: '1px solid red' } : {}),
            }
          : {}
      }
    >
      {isLoading ? (
        <TextSkeleton {...skeletonProps} />
      ) : (
        <Typography
          fontSize={14}
          color={rest.error ? 'red' : 'rgb(0 0 0 / 50%)'}
        >
          {label}
          {rest.required && <span style={{ color: 'red' }}> *</span>}
        </Typography>
      )}
      {isLoading ? (
        <TextSkeleton {...skeletonProps} />
      ) : isEdit ? (
        <TextField
          variant="standard"
          placeholder={placeholder}
          value={value}
          fullWidth
          sx={{
            '.MuiInputBase-root': {
              '&.MuiInput-root:before, &.MuiInput-root:after': {
                borderBottom: '0px!important',
              },
              input: {
                padding: 'unset',
                fontSize: 16,
                fontWeight: 600,
                color,
              },
            },
          }}
          {...rest}
        />
      ) : (
        <Typography fontSize={16} fontWeight={600}>
          {value}
        </Typography>
      )}
    </Box>
  )
}
