import {
  ChangeEvent,
  SyntheticEvent,
  useState,
  useEffect,
  useContext,
} from 'react'
import { Box } from '@mui/system'
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Button,
  Modal,
} from '@mui/material'
import { Navigate } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import {
  getToken,
  setAuth,
  useGetUserInfo,
  useLoginMutation,
  useRecoverMutation,
} from '../services/auth.service'

import LoginBg from '../assets/images/login-bg.png'
import Logo from '../assets/images/logo.png'
import WaveLine from '../assets/images/wave-line.png'
import LoginBanner from '../assets/images/login-banner.png'
import Testimony1 from '../assets/images/testimony1.png'
import Testimony2 from '../assets/images/testimony2.png'
import Testimony3 from '../assets/images/testimony3.png'
import GoogleLogo from '../assets/images/google-logo.png'
import WavePopup from '../assets/images/wave-line-popup.png'
import CloseIcon from '@mui/icons-material/Close'
import RecoverImage from '../assets/images/recover-image.png'
import VerifyImage from '../assets/images/verify.png'
import {
  CheckScreenSize,
  fixUrl,
  getServerUnauthorizedError,
} from '../helpers/utils'
import CustomButton from '../components/customButton'
import { PublicContext } from '../App'

const Login = (props: any) => {
  const { setNotification } = useContext(PublicContext)
  const isAuthenticated = getToken() !== null

  const [showVerifyEmailScreen, setShowVerifyEmailScreen] = useState(false)
  const [showRecoveryScreen, setShowRecoveryScreen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  })
  const [credential, setCredential] = useState({
    email: '',
    password: '',
  })

  const [recoverErrors, setRecoverErrors] = useState({
    email: false
  })

  const [recovery, setRecovery] = useState({
    email: '',
  })

  const { isLoading: isLoadingRecovery, mutate: recover } = useRecoverMutation()

  const { isLoading, mutate: login } = useLoginMutation()
  const { refetch } = useGetUserInfo()

  const queryParams = new URLSearchParams(window.location.search)
  const error = queryParams.get('error')
  const uriToken = queryParams.get('token')

  useEffect(() => {
    if (uriToken && !isAuthenticated) {
      setAuth({ token: uriToken })
      setTimeout(() => refetch())
    }
  }, [uriToken]) // eslint-disable-line

  useEffect(() => {
    if (error) {
      setNotification('Something went wrong.', 'error')
    }
  }, [error]) // eslint-disable-line

  const LoginWithGoogle = () => {
    window.location.href = fixUrl(
      process.env.REACT_APP_BASE_URL,
      'api/login/google'
    )
    return
  }

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setCredential((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validate = () => {
    let payload = [
      {
        key: 'email',
        isValid: credential.email !== '',
      },
      {
        key: 'password',
        isValid: credential.password !== '',
      },
    ]

    return {
      data: payload,
      isValid: payload.every((data) => data.isValid),
    }
  }

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()

    const validation = validate()

    if (!validation.isValid) {
      setErrors(
        validation.data.reduce(
          (prevField, field) => {
            return { ...prevField, [field.key]: !field.isValid }
          },
          { email: false, password: false }
        )
      )
      return
    }

    login(credential, {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          setNotification(getServerUnauthorizedError(error), 'error')
        }
      },
    })
  }

  const onChangeEmail = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setRecovery((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateRecovery = () => {
    let payload = [
      {
        key: 'email',
        isValid: recovery.email !== '',
      },
    ]

    return {
      data: payload,
      isValid: payload.every((data) => data.isValid),
    }
  }

  const onRecoverySubmit = async (event: SyntheticEvent) => {
    event.preventDefault()

    const validation = validateRecovery()

    if (!validation.isValid) {
      setRecoverErrors(
        validation.data.reduce(
          (prevField, field) => {
            return { ...prevField, [field.key]: !field.isValid }
          },
          { email: false }
        )
      )
      return
    }

    recover(recovery, {
      onError: (error: any) => {
        if (error?.response?.status === 422) {
          setNotification(getServerUnauthorizedError(error), 'error')
        }
      },
      onSuccess: (data) => {
        handleClose()
        setShowVerifyEmailScreen(true)
        setNotification('Successfuly sent reset password link', 'success')
        return
      },
    })
  }

  const handleClose = () => {
    setShowRecoveryScreen(false)
  }
  const handleCloseReset = () => {
    setShowVerifyEmailScreen(false)
  }
  const handleRecoverPassword = () => {
    setShowRecoveryScreen(true)
  }

  const isDesktop = CheckScreenSize('(min-width: 1200px)')
  const isMediumScreenMin = CheckScreenSize('(min-width: 900px)')
  const isMediumScreenMax = CheckScreenSize('(max-width: 1199px)')
  const isMediumScreen = isMediumScreenMin && isMediumScreenMax

  const isSmallestScreen = CheckScreenSize('(max-width: 599px)')

  const isSmallScreenMin = CheckScreenSize('(min-width: 600px)')
  const isSmallScreenMax = CheckScreenSize('(max-width: 899px)')
  const isSmallScreen =
    isSmallestScreen || (isSmallScreenMax && isSmallScreenMin)

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallestScreen ? '80%' : '500px',
    backgroundColor: '#fff',
    boxShadow: 24,
    borderRadius: '12px',
    '& img': {
      width: isSmallestScreen ? '100px' : 'auto',
    },
  }

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: '#FFF18E',
          height: '100vh',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        data-testid="login-user-form"
      >
        {!isSmallScreen && (
          <Box
            sx={{
              width: '50%',
              backgroundImage: `url(${WaveLine})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 70%',
              backgroundPosition: 'bottom right',
              zIndex: 1,
              position: 'relative',
              '.logo': {
                ml: '100px',
                mt: '80px',
              },
              '.banner': {
                position: 'absolute',
                bottom: 0,
                right: isMediumScreen ? 0 : -130,
                height: '80%',
              },
              '.object': {
                position: 'absolute',
                objectFit: 'contain',
              },
              '.testimony': {
                right: -100,
                '&2': {
                  top: 200,
                },
                '&1': {
                  bottom: 120,
                  right: -155,
                },
                '&3': {
                  bottom: 30,
                },
              },
            }}
          >
            <img className="logo" src={Logo} alt="Logo" />
            <img className="banner" src={LoginBanner} alt="Banner" />
            <img
              className="object testimony testimony1"
              src={Testimony1}
              alt="testimony1"
              data-value={isSmallScreen ? '0' : '-5'}
            />
            <img
              className="object testimony testimony2"
              src={Testimony2}
              alt="testimony2"
              data-value={isSmallScreen ? '0' : '8'}
            />
            <img
              className="object testimony  testimony3"
              src={Testimony3}
              alt="testimony3"
              data-value={isSmallScreen ? '0' : '3'}
            />
          </Box>
        )}
        <Box
          sx={{
            width: !isSmallScreen ? '50%' : '100%',
            backgroundImage: `url(${LoginBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: !isSmallScreen ? 'unset' : '20px',
          }}
        >
          <Box
            sx={{
              width: !isSmallScreen ? '400px' : '100%',
              margin: isSmallScreen ? '5px' : 'unset',
              mb: !isDesktop ? '40px' : 'unset',
              h4: {
                textAlign: 'center',
                fontSize: 36,
                fontWeight: 'bold',
                padding: 0,
                color: '#000',
                mb: '10px',
              },
              p: {
                textAlign: 'center',
                fontSize: 15,
                fontWeight: 400,
                color: '#7e7e7e',
                margin: 0,
                padding: 0,
              },
              '.sub-title': {
                fontSize: 18,
                color: '#000',
                mb: '40px',
              },
              '.login-with-google': {
                background: ' #ffffff',
                border: '1px solid #dedede',
                padding: '6px 20px',
                textTransform: 'none',
                color: '#000000',
                mb: '25px',
                '&:hover': {
                  background: ' #ffffff',
                },
                span: {
                  mt: '4px',
                },
                img: {
                  mr: '8px',
                  width: '22px',
                },
              },
            }}
          >
            <Box>
              <h4>Welcome Back</h4>
              <div className="sub-title">Login into your account</div>
              <div>
                <Button className="login-with-google" onClick={LoginWithGoogle}>
                  <img src={GoogleLogo} alt="Login with google" />
                  <span>Google</span>
                </Button>
              </div>
            </Box>
            <Box
              sx={{
                padding: '0 5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& div': { width: '100%' },
                '& div.line': {
                  borderTop: '1px solid rgb(38 31 114 / 26%)',
                },
                '& div.label': {
                  textAlign: 'center',
                  fontSize: '13px',
                  color: '#000',
                },
              }}
            >
              <div className="line" />
              <div className="label">Or continue with</div>
              <div className="line" />
            </Box>
            <Box
              component="form"
              className="login-form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              sx={{
                mt: isSmallestScreen ? '16px!important' : '10px',
                '.MuiInputBase-root': {
                  bgcolor: 'white',
                  borderRadius: '10px',
                },
                '& input': {
                  fontSize: '14px',
                  padding: '25px 14px 24px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '& .MuiFormControl-root': {
                  mt: '10px',
                },
              }}
            >
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.email}
              >
                <OutlinedInput
                  fullWidth
                  placeholder="Email"
                  id="email"
                  type="email"
                  onChange={onChange('email')}
                />
              </FormControl>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.password}
              >
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  id="password"
                  onChange={onChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        data-testid="password-visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Grid
                container
                sx={{
                  overflow: 'auto',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: isSmallestScreen ? 'column' : 'row',
                }}
                justifyContent="flex-start"
              >
                <Grid item>
                  <Button
                    disableElevation
                    disableRipple
                    sx={{
                      color: '#2A66FF',
                      textDecoration: 'none',
                      background: 'none',
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent"
                      }
                    }}
                    onClick={handleRecoverPassword}
                  >
                    Recover Password
                  </Button>
                </Grid>
                <Grid sx={{ width: isSmallestScreen ? '100%' : 'auto' }}>
                  <Box
                    mt={isSmallestScreen ? '16px!important' : ''}
                    className="login-action"
                  >
                    <CustomButton
                      label="Log In"
                      type="submit"
                      isLoading={isLoading}
                      sx={{
                        padding: '8px!important',
                        width: isSmallestScreen ? '100%' : '120px',
                      }}
                      className="likester-button"
                    />
                  </Box>
                </Grid>
                <Box
                  sx={{
                    mt: '20px',
                    textAlign: 'center',
                    width: '100%',
                    color: '#000',
                    a: {
                      textDecoration: 'none',
                      color: '#2A66FF',
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <p>
                    Don't have an account? <a href="/register">Sign Up!</a>
                  </p>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={showRecoveryScreen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              color: '#281C58',
              fontWeight: '700',
              fontSize: '32px',
              backgroundImage: 'linear-gradient(#eeccc0, #f595ea)',
              borderRadius: '12px 12px 0 0 ',
              textAlign: 'center',
              '& img': {
                marginTop: '-115px',
              },
              '& .wave': {
                p: 4,
                backgroundImage: `url(${WavePopup})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
              },
              '& svg': {
                color: '#fff',
                fontSize: isSmallestScreen ? '30px' : '45px',
                position: 'absolute',
                right: isSmallestScreen ? '-23px' : '-27px',
                top: isSmallestScreen ? '-20px' : '-25px',
                backgroundColor: '#3791F0',
                borderRadius: '35px',
                padding: '5px',
                cursor: 'pointer',
              },
            }}
          >
            <div className="wave">
              <CloseIcon onClick={handleClose} />
              <img src={RecoverImage} alt="" />
              <br />
              <br />
              Forgot Password
            </div>
          </Box>
          <Box
            sx={{
              p: 3,
              color: '#000000;',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              textAlign: 'center',
              '& a': {
                color: '#FF4EA3',
              },
              '& p': {
                textAlign: 'center',
                fontSize: '13px',
              },
            }}
          >
            No worries, We’ll send you reset instructions.
            <br />
            <Box
              component="form"
              className="recover-form"
              noValidate
              autoComplete="off"
              onSubmit={onRecoverySubmit}
              sx={{
                mt: isSmallestScreen ? '16px!important' : '10px',
                '.MuiInputBase-root': {
                  bgcolor: 'white',
                  borderRadius: '10px',
                },
                '& input': {
                  fontSize: '14px',
                  padding: '25px 14px 24px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#D9D9D9',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D9D9D9',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D9D9D9',
                  },
                },
              }}
            >
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={recoverErrors.email}
              >
                <OutlinedInput
                  fullWidth
                  placeholder="Email"
                  id="email"
                  type="email"
                  onChange={onChangeEmail('email')}
                />
              </FormControl>

              <CustomButton
                label="Reset Password"
                type="submit"
                isLoading={isLoadingRecovery}
                sx={{
                  padding: '18px!important',
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'block',
                  width: '100%'
                }}
                className="likester-button"
              />
            </Box>


            <Box
              sx={{
                '& a': {
                  cursor: 'pointer'
                }
              }}
            >
              <p>
                Go back to login{' '}
                <a onClick={handleClose} >
                  login
                </a>
              </p>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showVerifyEmailScreen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              color: '#281C58',
              fontWeight: '700',
              fontSize: '32px',
              backgroundImage: 'linear-gradient(#eeccc0, #f595ea)',
              borderRadius: '12px 12px 0 0 ',
              textAlign: 'center',
              '& img': {
                marginTop: '-115px',
              },
              '& .wave': {
                p: 4,
                backgroundImage: `url(${WavePopup})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
              },
              '& svg': {
                color: '#fff',
                fontSize: isSmallestScreen ? '30px' : '45px',
                position: 'absolute',
                right: isSmallestScreen ? '-23px' : '-27px',
                top: isSmallestScreen ? '-20px' : '-25px',
                backgroundColor: '#3791F0',
                borderRadius: '35px',
                padding: '5px',
                cursor: 'pointer',
              },
            }}
          >
            <div className="wave">
              <CloseIcon onClick={handleCloseReset} />
              <img src={VerifyImage} alt="" />
              <br />
              <br />
              Verify your Email Address
            </div>
          </Box>
          
          <Box
            sx={{
              p: 3,
              color: '#000000;',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              textAlign: 'center',
              '& a': {
                color: '#FF4EA3',
              },
              '& p': {
                textAlign: 'center',
                fontSize: '13px',
              },
              '& span': {
                color: '#FF4EA3'
              }
            }}
          >
            We sent an email containing the password <br />
            recovery link to &nbsp;
            <span>`{ recovery.email } `</span>
            <br />
          </Box>
          <Box
            sx={{
              p: 3,
              color: '#000000;',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              textAlign: 'center',
              '& a': {
                color: '#FF4EA3',
              },
              '& p': {
                textAlign: 'center',
                fontSize: '13px',
              },
              '& span': {
                color: '#FF4EA3'
              }
            }}
          >
            Didn’t see any emails? <br />
            Try checking your spam folder or
            <br />
            <Box
              component="form"
              className="recover-form"
              noValidate
              autoComplete="off"
              onSubmit={onRecoverySubmit}
            >

              <CustomButton
                label="Resend Link"
                type="submit"
                isLoading={isLoadingRecovery}
                sx={{
                  padding: '18px!important',
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'block',
                  width: '100%'
                }}
                className="likester-button"
              />
            </Box>
          </Box>
          <Box
            sx={{
              paddingBottom: '20px',
              display: 'block',
              '& p': {
                fontSize: '14px',
                textAlign: 'center'
              },
              '& a': {
                cursor: 'pointer',
                color: '#FF4EA3',
              }
            }}
          >
            <p>
              Questions? Email us at  &nbsp;
              <a href='mailto:likestersupport@likester.com' >
                likestersupport@likester.com
              </a>
            </p>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Login
