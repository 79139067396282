import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useGetFile } from '../../services/file.service'
import { useEffect } from 'react'
import useAccountStore from '../../store/account.store'

export type ProfileTypes = {
  isAdd?: boolean
  profile?: any
  isSelected?: boolean
  isLoading?: boolean
  onAddProfile?: Function
}

export default function Profile(props: ProfileTypes) {
  const {
    isAdd = false,
    profile = {},
    isSelected,
    isLoading,
    onAddProfile,
  } = props
  const { setSelectedAccount } = useAccountStore()

  const photo = profile?.photo
  const { data: file, refetch: getFile } = useGetFile(photo)

  useEffect(() => {
    if (photo) {
      getFile()
    }
  }, [photo]) // eslint-disable-line

  let icon = null

  if (isAdd) {
    icon = <AddIcon />
  }

  if (isSelected) {
    icon = <CheckIcon />
  }

  if (isSelected && isLoading) {
    icon = <RefreshIcon className="loading-profile-icon" />
  }

  let statusColor = 'transparent'
  if (isSelected) {
    statusColor = '#FF4EA3'
  }

  return (
    <Box p="0px 10px">
      <Box
        position="relative"
        onClick={() => {
          !isLoading && setSelectedAccount(profile ?? null)
          onAddProfile && onAddProfile()
        }}
        sx={{
          '&:hover': {
            cursor: !isLoading ? 'pointer' : 'normal',
          },
        }}
      >
        <Box
          borderRadius="50%"
          width="55px"
          height="55px"
          bgcolor="#D5DFE7"
          border={`3px solid ${statusColor}`}
          sx={
            !isAdd && profile.photo
              ? {
                  background: `url(${file})`,
                  backgroundSize: 'cover',
                }
              : {}
          }
        />
        <Box
          width="16px"
          height="16px"
          bgcolor={isSelected || isAdd ? '#00B67A' : 'none'}
          display="flex"
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          right="20px"
          bottom="2px"
          sx={{
            svg: {
              fontSize: 11,
              color: '#fff',
            },
          }}
        >
          {icon}
        </Box>
      </Box>
      <Box width="80px" mt={1} textAlign="center">
        <Typography
          color={isSelected ? '#261F72' : '#707A89'}
          fontSize={16}
          fontWeight={500}
          fontStyle="normal"
        >
          {isAdd ? 'Add Profile' : profile?.user_name ?? ''}
        </Typography>
      </Box>
    </Box>
  )
}
