import { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { AppContext } from '../Protected'
import { useGetUserAccountsSubscriptions } from '../services/auth.service'
import SubscriptionAccount from '../components/dashboard/subscriptions/subscriptions-account'
import RectangularSkeleton from '../components/skeletons/rectangular.skeleton'

export default function SubscriptionScreen() {
  const { isDesktop } = useContext(AppContext)
  const [subscriptions, setSubscription] = useState<any>([])

  const { data, refetch, isLoading } = useGetUserAccountsSubscriptions()

  useEffect(() => {
    if (data && !isLoading) {
      setSubscription(data)
    }
  }, [data, isLoading])

  return (
    <Box
      p={!isDesktop ? '5px 10px 100px 20px' : '0px 40px 0px 40px'}
      overflow="auto"
    >
      {isLoading ? (
        <RectangularSkeleton
          height="204px"
          width="100%"
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <>
          {subscriptions && subscriptions.length > 0
            ? subscriptions.map((subscription: any, index: number) => {
                return (
                  <SubscriptionAccount
                    key={`account-${index}`}
                    isLoading={isLoading}
                    subscription={subscription}
                    isDesktop={isDesktop}
                    refetch={refetch}
                  />
                )
              })
            : 'No Subscriptions.'}
        </>
      )}
    </Box>
  )
}
