import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card/Card'
import Stack from '@mui/material/Stack/Stack'
import Typography from '@mui/material/Typography'
import { Button, Grid } from '@mui/material'
import CustomSwitch from '../customSwitch'
import { ReactComponent as HeartIcon } from '../../assets/images/icons/hearts.svg'
import { ReactComponent as CommentIcon } from '../../assets/images/icons/comments.svg'
import { ReactComponent as ShareIcon } from '../../assets/images/icons/shares.svg'
import PicturePlaceholder from '../../assets/images/avatars/pp-placeholder.png'
import { formatInstaNumber } from '../../helpers/utils'

export type PostTypes = {
  id: number
  date: string
  like_count: number
  shortcode: string
  comment_count: number
  view_count: number
  thumbnail: string
  status: string
  onChangePost?: Function
  isOpen?: boolean
  post?: PostTypes
  isAddDialog?: boolean
}

export default function Post(props: any) {
  const { isDesktop, post, onChangePost } = props

  const {
    like_count = 0,
    comment_count = 0,
    view_count = 0,
    status = 'started',
    image_url = '',
  } = post ?? {}
  const isAdd = props.isAdd ?? false

  const isStarted = status === 'started'

  return (
    <>
      <Grid item xs={6} md={3}>
        <Card
          sx={{
            borderRadius: '10px',
            height: isDesktop ? '282px' : '200px',
            background: isAdd
              ? '#D5DFE7'
              : `url(https://www.followerpackages.com/services/image_orig.php?url=${image_url}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            height="100%"
            position="relative"
            // sx={{
            //   '&:hover': {
            //     '.change-post': {
            //       visibility: 'visible',
            //     },
            //   },
            // }}
          >
            <Box
              sx={{
                color: '#fff',
                bgcolor: '#0000004d',
                zIndex: 1,
                position: 'absolute',
                right: 0,
                width: '100%',
                height: '30px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                paddingRight: 2,
                visibility: isAdd ? 'hidden' : 'visible',
              }}
            >
              {isStarted ? 'Started' : 'On Hold'}
            </Box>
            {/* <CustomSwitch
              checked={isStarted}
              label={isStarted ? 'Started' : 'On Hold'}
              labelPlacement="start"
              customStyles={{
                color: '#fff',
                bgcolor: '#0000004d',
                zIndex: 1,
                position: 'absolute',
                right: 0,
                width: '100%',
                visibility: isAdd ? 'hidden' : 'visible',
              }}
            /> */}
            <Box
              bgcolor={isAdd ? 'transparent' : '#302C4Ec2'}
              position="absolute"
              top={0}
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              visibility={isAdd ? 'visible' : 'hidden'}
              className="change-post"
            >
              {
                isAdd && (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: '10px',
                      color: '#fff',
                      border: '1px solid #00B67A',
                      bgcolor: '#00B67A',
                      textTransform: 'capitalize',
                      letterSpacing: 1.2,
                      '&:hover': {
                        border: '1px solid #00B67A',
                        bgcolor: '#00B67A',
                      },
                    }}
                    onClick={() => onChangePost && onChangePost(true)}
                  >
                    Add Post
                  </Button>
                )
                // : (
                //   <Button
                //     variant="outlined"
                //     sx={{
                //       borderRadius: '10px',
                //       color: '#fff',
                //       border: '1px solid #fff',
                //       textTransform: 'capitalize',
                //       letterSpacing: 1.2,
                //       '&:hover': {
                //         border: '1px solid #fff',
                //       },
                //     }}
                //     onClick={() => onChangePost && onChangePost(false, post)}
                //   >
                //     Change Post
                //   </Button>
                // )
              }
            </Box>
          </Box>
          <Stack
            bgcolor="#fff"
            p="7px 10px"
            direction="row"
            justifyContent="space-around"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0.4}
            >
              <HeartIcon />
              <Typography color="#261F72">
                {isAdd ? '-' : formatInstaNumber(like_count)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0.4}
            >
              <CommentIcon />
              <Typography color="#261F72">
                {isAdd ? '-' : formatInstaNumber(comment_count)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0.4}
            >
              <ShareIcon />
              <Typography color="#261F72">
                {isAdd ? '-' : formatInstaNumber(view_count)}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  )
}
