import Dashboard from './screens/dashboard.screen'
import Login from './screens/login.screen'
import Subscription from './screens/subscription.screen'
import AccountSettings from './screens/account-settings.screen'
import Help from './screens/help.screen'
import {
  TYPE_CUSTOMER,
  TYPE_SUPER_ADMIN,
  TYPE_WORKER,
} from './types/user.types'
import Register from './screens/register.screen'

import { ReactComponent as DashboardIcon } from './assets/images/icons/dashboard.svg'
import { ReactComponent as SubscriptionIcon } from './assets/images/icons/subscriptions.svg'
import { ReactComponent as SettingsIcon } from './assets/images/icons/setting.svg'
import { ReactComponent as HelpIcon } from './assets/images/icons/help.svg'
import { ReactComponent as OrdersIcon } from './assets/images/icons/orders.svg'
import { ReactComponent as CouponIcon } from './assets/images/icons/coupon.svg'
import { ReactComponent as PackagesIcon } from './assets/images/icons/packages.svg'
import { ReactComponent as CustomerIcon } from './assets/images/icons/customers.svg'

import CustomersScreen from './screens/customer.screen'
import OrdersScreen from './screens/orders.screen'
import PackageScreen from './screens/packages.screen'
import CouponScreen from './screens/coupon.screen'
import ResetPassword from './screens/reset-password.screen'

export type RouteType = {
  path: string
  component: any
  name?: string
  isProtected?: boolean
  icon?: any
  accessRoles?: string[]
  hideTitle?: boolean
  hideSearch?: boolean
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    isProtected: true,
    icon: <DashboardIcon />,
    accessRoles: [TYPE_CUSTOMER, TYPE_SUPER_ADMIN, TYPE_WORKER],
    hideTitle: true,
    hideSearch: true,
  },
  {
    path: '/subscription',
    name: 'Subscriptions',
    component: Subscription,
    isProtected: true,
    icon: <SubscriptionIcon />,
    accessRoles: [TYPE_CUSTOMER],
  },
  {
    path: '/account-settings',
    name: 'Account Settings',
    component: AccountSettings,
    isProtected: true,
    icon: <SettingsIcon />,
    accessRoles: [TYPE_CUSTOMER],
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    isProtected: true,
    icon: <HelpIcon />,
    accessRoles: [TYPE_CUSTOMER],
    hideTitle: true,
    hideSearch: true,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersScreen,
    isProtected: true,
    icon: <CustomerIcon />,
    accessRoles: [TYPE_SUPER_ADMIN],
    hideTitle: true,
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersScreen,
    isProtected: true,
    icon: <OrdersIcon />,
    accessRoles: [TYPE_SUPER_ADMIN],
    hideTitle: true,
    fillPath: true,
  },
  {
    path: '/packages',
    name: 'Packages',
    component: PackageScreen,
    isProtected: true,
    icon: <PackagesIcon />,
    accessRoles: [TYPE_SUPER_ADMIN],
    hideTitle: true,
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: CouponScreen,
    isProtected: true,
    icon: <CouponIcon />,
    accessRoles: [TYPE_SUPER_ADMIN],
    hideTitle: true,
    fillPath: true,
  },
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/register',
    component: <Register />,
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
  },
]

export default routes
