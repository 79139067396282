import React from 'react'
import { Alert, AlertColor, Slide, Snackbar } from '@mui/material'

type Props = {
  message: string | undefined
  open: boolean
  severity?: AlertColor
  onClose: Function
}

export default function Notification(props: Props) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    event?.preventDefault()

    if (reason === 'clickaway') {
      return
    }

    props.onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleClose}
      open={props.open}
      TransitionComponent={Slide}
      data-testid="error-message"
      autoHideDuration={props.severity === 'success' ? 5000 : null}
    >
      <Alert variant="filled" onClose={handleClose} severity={props.severity}>
        {props?.message ?? ''}
      </Alert>
    </Snackbar>
  )
}
