import { Box } from '@mui/material'
import { createContext, useState, useEffect, ReactNode } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import NavBarWrapper from './components/wrappers/navbar.wrapper'
import ContentWrapper from './components/wrappers/content.wrapper'
import {
  getCurrentUser,
  getToken,
  useGetUserInfo,
} from './services/auth.service'
import { CheckScreenSize } from './helpers/utils'
import {
  TYPE_CUSTOMER,
  TYPE_SUPER_ADMIN,
  TYPE_WORKER,
} from './types/user.types'
import routes, { RouteType } from './routes'
import SearchContextProvider from './contexts/search.context'

export type ProtectedRouteProps = {
  Outlet: any
  setNotification: Function
}

type AppContextProps = {
  navigate: Function
  setNotification: Function
  userDetails: any
  isAuthenticated: boolean
  isDesktop: boolean
  isSmallestScreen: boolean
  isSmallScreen: boolean
  isLoading: boolean
  isCustomer: boolean
  isAdmin: boolean
  isWorker: boolean
  activeRoute: RouteType | undefined
  getHeaderAddOns: Function
  headerAddons?: ReactNode | Element | null
  isLoaderShown: boolean
  setIsLoaderShown: Function
}

export const AppContext = createContext({} as AppContextProps)

export default function ProtectedRoute({
  Outlet,
  setNotification,
}: ProtectedRouteProps) {
  const location = useLocation()

  const activeRoute = routes.find(
    (route: RouteType) => route.path === location.pathname
  )

  const [headerAddons, setHeaderAddOns] = useState<Element | null>(null)
  const [isLoaderShown, setIsLoaderShown] = useState(true)
  const isDesktop = CheckScreenSize('(min-width: 1200px)')
  const isSmallestScreen = CheckScreenSize('(max-width: 599px)')

  const isSmallScreenMin = CheckScreenSize('(min-width: 600px)')
  const isSmallScreenMax = CheckScreenSize('(max-width: 899px)')
  const isSmallScreen =
    isSmallestScreen || (isSmallScreenMax && isSmallScreenMin)

  const isAuthenticated = getToken() !== null
  const navigate = useNavigate()

  const { isLoading, refetch } = useGetUserInfo()

  useEffect(() => {
    setHeaderAddOns(null)
  }, [activeRoute])

  useEffect(() => {
    if (isAuthenticated) refetch()
  }, []) //eslint-disable-line

  const getHeaderAddOns = (addOns: Element | null) => {
    setHeaderAddOns(addOns)
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  const userDetails = getCurrentUser()
  const isCustomer = (userDetails?.roles ?? []).includes(TYPE_CUSTOMER)
  const isAdmin = (userDetails?.roles ?? []).includes(TYPE_SUPER_ADMIN)
  const isWorker = (userDetails?.roles ?? []).includes(TYPE_WORKER)

  /**
   * Context values should only contain:
   * 1. Functions that are used in places within the app. Add them here.
   * 2. Constant properties/variables to avoid unnecessary rerender of children components
   */
  return (
    <AppContext.Provider
      value={{
        userDetails,
        navigate,
        setNotification,
        isLoading,
        isAuthenticated,
        isDesktop,
        isSmallestScreen,
        isSmallScreen,
        isCustomer,
        isAdmin,
        isWorker,
        activeRoute,
        getHeaderAddOns,
        headerAddons,
        isLoaderShown,
        setIsLoaderShown,
      }}
    >
      <Box display="flex" bgcolor="#F6FBFF" height="100vh" overflow="hidden">
        <NavBarWrapper />
        <SearchContextProvider>
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
        </SearchContextProvider>
      </Box>
    </AppContext.Provider>
  )
}
