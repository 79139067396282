import { Box, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { serviceQualityTypes } from '../../../types/subscription.types'
import { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../../Protected'
import { capitalize, debounce } from 'lodash'
import ArrowPrev from '@mui/icons-material/ArrowBackIos'
import ArrowNext from '@mui/icons-material/ArrowForwardIos'
import Carousel from 'react-material-ui-carousel'

const packageWidth = 220
const scrollInterval = packageWidth * 2

const Pricing = (props: any) => {
  const { isDesktop, isSmallestScreen } = useContext(AppContext)
  const {
    packages,
    serviceType,
    onlyFirstPackage = false,
    onSelectPackage = null,
    selectedPackage,
  } = props
  const [qualityValue, setQualityValue] = useState<string>(
    serviceQualityTypes['high_quality'].value
  )
  const [servicePackages, setServicePackages] = useState<any>([])
  const servicePackageRef = useRef<HTMLDivElement>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const [keyForForceRerenderPricing, setKeyForForceRerenderPricing] = useState(
    Math.random()
  )
  const [pricingContainerWidth, setPricingContainerWidth] = useState('100%')
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)

  useEffect(() => {
    const handleResize = debounce(() => {
      handleSetPricingContainerWidth()
      if (divRef.current) {
        divRef.current.scrollLeft = 0
      }
    }, 100)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleSetPricingContainerWidth()
  }, [servicePackageRef.current]) //eslint-disable-line

  const handleSetPricingContainerWidth = () => {
    setKeyForForceRerenderPricing(Math.random())
    if (servicePackageRef.current) {
      const { clientWidth = 0 } = servicePackageRef.current
      if (clientWidth % packageWidth > 0) {
        setPricingContainerWidth(
          `${clientWidth - (clientWidth % packageWidth)}px`
        )
      }
    }
  }

  useEffect(() => {
    if (!packages) return

    if (!onlyFirstPackage) {
      setServicePackages(
        packages.filter((p: any) => {
          if (serviceType === 'comments') {
            return p.service === 'comments'
          }
          return p.service === `${qualityValue}_${serviceType}`
        })
      )
      return
    }

    // First package in each service by Quality
    const firstLike = packages.find(
      (p: any) => p.service === `${qualityValue}_likes`
    )

    const firstView = packages.find(
      (p: any) => p.service === `${qualityValue}_views`
    )
    const firstComment = packages.find((p: any) => p.service === `comments`)
    setServicePackages([
      ...(firstLike ? [{ ...firstLike, type: 'likes' }] : []),
      ...(firstView ? [{ ...firstView, type: 'views' }] : []),
      ...(firstComment ? [{ ...firstComment, type: 'comments' }] : []),
    ])
  }, [serviceType, qualityValue, onlyFirstPackage, packages]) // eslint-disable-line

  const handleScroll = (position: number) => {
    if (divRef.current) {
      const {
        clientWidth = 0,
        scrollWidth = 0,
        scrollLeft = 0,
      } = divRef.current

      const maxScroll = scrollWidth - clientWidth

      if (position > maxScroll && position - maxScroll <= scrollInterval) {
        position = maxScroll
      } else if (position < 0 && scrollLeft > 0) {
        position = 0
      }

      if (position >= 0 && position <= maxScroll) {
        divRef.current.scrollTo({
          left: position,
          behavior: 'smooth', // Add smooth scrolling animation
        })
        setCurrentScrollPosition(position)
      }
    }
  }

  const getServicePackages = (p: any) => {
    return p.map((sp: any, index: number) => {
      const isActive = sp.id === selectedPackage?.id
      const [price, cents] = (sp?.rate ?? '').toString().split('.')
      const sType = serviceType || sp?.type
      return (
        <div className="package" key={'package' + index + qualityValue}>
          <div className="count">{sp.quantity}</div>
          <div className="title">{capitalize(sType)}</div>
          <div className="subtitle">per post</div>
          <div className="price">
            $<span>{price}</span>
            <span>.{cents}</span>
            <span> / month</span>
          </div>
          {isActive ? (
            <Button
              fullWidth
              className="warning"
              onClick={() => onSelectPackage(null)}
            >
              Remove
            </Button>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                onSelectPackage &&
                  onSelectPackage({
                    ...sp,
                    quality: qualityValue,
                    type: serviceType,
                  })
              }}
            >
              Subscribe
            </Button>
          )}
        </div>
      )
    })
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {serviceType !== 'comments' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: isDesktop ? 3 : 1,
            div: {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              bgcolor: '#FFFFFF',
              margin: 'unset',
              padding: isDesktop ? '15px 30px' : '10px 20px',
              color: '#474747',
              fontSize: isDesktop ? 'inherit' : '14px',
              svg: {
                mr: '10px',
                color: '#bbb',
              },
              '&.active': {
                bgcolor: '#4862FF',
                border: '1px solid #4862FF',
                color: '#fff',
                svg: {
                  color: '#fff',
                },
              },
              '&.inactive': {
                border: '1px solid #e5e5e5',
              },
              '&:first-of-type': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              },
              '&:last-of-type': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              },
            },
          }}
        >
          {Object.values(serviceQualityTypes).map(
            ({ value, label }: any, index: number) => {
              const isActive = qualityValue === value
              return (
                <div
                  key={'quality' + index}
                  className={isActive ? 'active' : 'inactive'}
                  onClick={() => setQualityValue(value)}
                >
                  {isActive ? (
                    <CheckIcon
                      style={{
                        color: '#fff',
                        background: '#05b67a',
                        borderRadius: '15px',
                        fontSize: '15px',
                        padding: '4px',
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                  {label}
                </div>
              )
            }
          )}
        </Box>
      )}

      <Box
        ref={servicePackageRef}
        sx={{
          display: 'flex',
          mt: '40px',
          overflow: 'hidden',
          width: '100%',
          '.package': {
            width: isDesktop ? '150px' : 'unset',
            border: '1px solid #e5e5e5',
            bgcolor: '#fff',
            padding: '20px 15px',
            borderRadius: '15px',
            color: '#281C58',
            cursor: 'pointer',
            textAlign: 'center',
            mr: isDesktop ? '20px' : 'unset',
            minWidth: '170px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            '&.active': {
              outline: '3px solid #4862FF',
            },
            '&.loading': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
            '.sp-header': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '.sale': {
                bgcolor: '#FFAC67',
                color: '#fff',
                padding: '5px 8px',
                mb: '20px',
              },
              svg: {
                color: '#4862FF',
                fontSize: '50px',
                position: 'absolute',
                top: '5px',
                right: '5px',
              },
            },
            '.count': {
              fontSize: '42px',
              fontWeight: 'bold',
            },
            '.title': {
              fontSize: '16px',
              fontWeight: 'bold',
            },
            '.subtitle': {
              fontSize: '14px',
            },
            '.price': {
              mt: '20px',
              fontSize: '25px',
              'span:nth-of-type(1n)': {
                fontWeight: 'bold',
              },
              'span:nth-of-type(2n)': {
                fontSize: '20px',
                fontWeight: 'bold',
              },
              'span:nth-of-type(3n)': {
                fontSize: '14px',
              },
            },
            button: {
              bgcolor: '#00B67A',
              color: '#fff',
              fontSize: '18px',
              textTransform: 'none',
              mt: '20px',
              borderRadius: '8px',
              padding: '5px 10px',
              '&.warning': {
                bgcolor: '#FFA500',
              },
            },
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {servicePackages.length > 0 ? (
          !isSmallestScreen ? (
            <Box
              key={keyForForceRerenderPricing}
              sx={{
                position: 'relative',
                width: pricingContainerWidth,
                overflow: 'hidden',
                '.desktop-packages': {
                  display: 'flex',
                  overflow: 'scroll',
                  width: '100%',
                  padding: '10px',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                },
                '.controls button': {
                  position: 'absolute',
                  bgcolor: 'rgb(255, 78, 163)',
                  color: '#fff',
                  borderRadius: '50%',
                  border: 0,
                  fontSize: '10px',
                  cursor: 'pointer',
                  top: '40%',
                  opacity: 0.3,
                  '&:hover': {
                    opacity: 0.9,
                  },
                  '&:first-of-type': {
                    left: 0,
                    padding: '10px 7px 7px 14px',
                  },
                  '&:last-child': {
                    right: 0,
                    padding: '10px 8px 7px 12px',
                  },
                },
              }}
            >
              <div className="desktop-packages" ref={divRef}>
                {getServicePackages(servicePackages)}
              </div>

              {(divRef?.current?.scrollWidth ?? 0) >
                (divRef?.current?.clientWidth ?? 0) && (
                <div className="controls">
                  <button
                    onClick={() =>
                      handleScroll(currentScrollPosition - scrollInterval)
                    }
                  >
                    <ArrowPrev />
                  </button>
                  <button
                    onClick={() =>
                      handleScroll(currentScrollPosition + scrollInterval)
                    }
                  >
                    <ArrowNext />
                  </button>
                </div>
              )}
            </Box>
          ) : (
            <Carousel
              autoPlay={false}
              navButtonsAlwaysVisible={true}
              sx={{
                width: '100%',
                p: '5px',
              }}
              navButtonsProps={{
                style: {
                  backgroundColor: '#FF4EA3',
                  color: '#fff',
                },
              }}
              indicatorContainerProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              {getServicePackages(servicePackages)}
            </Carousel>
          )
        ) : (
          <div>No packages offered as of the moment.</div>
        )}
      </Box>
    </Box>
  )
}

export default Pricing
