import { useContext } from 'react'
import { Box, Typography } from '@mui/material'

import NoPostImage from '../../../assets/images/nopost.png'
import CustomButton from '../../customButton'
import { AppContext } from '../../../Protected'

const NoPost = (props: any) => {
  const { setAddSubscription } = props
  const { isSmallScreen } = useContext(AppContext)
  const AddSubscription = () => {
    setAddSubscription(true)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          mt: '20px',
          '& img': {
            height: 'auto',
            width: isSmallScreen ? '90%' : '20%',
          },
        }}
      >
        <img src={NoPostImage} alt="No Post" />
        <Typography
          mt={3}
          sx={{ color: '#261F72', fontSize: 20, fontWeight: '700', mb: 3 }}
        >
          You don’t have any active subscription yet. <br /> Please add one to
          use the system.
        </Typography>
        <CustomButton
          disableElevation
          label="Add Subscription"
          className="likester-button"
          onClick={AddSubscription}
        />
      </Box>
    </Box>
  )
}

export default NoPost
