const endpoints = {
  CSRF_TOKEN: '/sanctum/csrf-cookie',
  LOGIN: '/api/login',
  REGISTER: '/api/register',
  INFO: 'api/int/me',
  GET_USERS: '/api/int/users',
  RECOVERY: '/api/password/email',
  RESET: '/api/password/reset',
  GET_ORDERS: '/api/int/orders',
  GET_USER_ACCOUNTS: '/api/int/accounts',
  UPDATE_USER: '/api/int/users/{0}',
  GET_USER_SUBSCRIPTIONS: '/api/int/subscriptions',
  UPDATE_USER_STATUS: '/api/int/users/{0}/{1}',
  COUPON: '/api/int/coupons',
  COUPON_CHECK: '/api/coupon/check',
  UPDATE_COUPON: '/api/int/coupons/{0}',
  OPTIONS: '/api/int/options',
  UPDATE_SUBSCRIPTION: '/api/int/subscriptions/{0}',
  SCRAPE_ACCOUNT_POSTS: '/api/int/accounts/{0}/scrape_posts',
  ACCOUNT_POST: '/api/int/accounts/{0}/posts',
  ADD_SUBSCRIPTION: '/api/int/accounts/{0}/addSubscription',
  GET_ACCOUNT_POSTS: '/api/int/accounts/{0}/posts',
  UPDATE_ACCOUNT_LIKES_PROPERITES: '/api/int/accounts/{0}',
  PACKAGE: '/api/int/packages',
  UPDATE_PACKAGE: '/api/int/packages/{0}',
  SCRAPE_IG_PROFILE: '/api/scrape/profile',
  ADD_ACCOUNT_PROFILE: '/api/int/accounts',
  PACKAGE_OPTIONS: '/api/packages',
  GET_STATISTICS: '/api/int/statistics',
}

const endpointsRequiringCSRFToken = [
  endpoints.LOGIN,
  endpoints.REGISTER,
  endpoints.SCRAPE_IG_PROFILE,
]

export const requiresCSRFToken = (endpoint: string): boolean =>
  endpointsRequiringCSRFToken.includes(endpoint)

export default endpoints
