export const serviceQualities = {
  'high-quality': 'High Quality',
  'premium-quality': 'Premium Quality',
}

export const SubscriptionTypesLabel = {
  likes: 'Likes',
  views: 'Views',
  comments: 'Comments',
}

export const SubscriptionStatus = {
  active: 'active',
  paused: 'paused',
  cancelled: 'cancelled',
  refund: 'refund',
  expired: 'expired',
}

export const serviceTypes = {
  comments: { value: 'comments', label: 'Instagram Comments' },
  views: { value: 'views', label: 'Instagram Views' },
  likes: { value: 'likes', label: 'Instagram Likes' },
}

export const serviceQualityTypes = {
  high_quality: { value: 'high_quality', label: 'High Quality' },
  premium_quality: { value: 'premium_quality', label: 'Premium Quality' },
}

export type PackageType = {
  id: number
  sale?: string
  count: string
  title: string
  subtitle: string
  price: string
  cents: string
  inclusions: string[]
}

export type SubscriptionType = {
  type: string
  qualityType: string
  package: PackageType
}

export type SubAccountType = {
  picture: string
  username: string
  billingDate: string
  enabled: boolean
  subscriptions: SubscriptionType[]
}

export const pricing = {
  likes: {
    'high-quality': [
      {
        id: 1,
        sale: '',
        count: '50',
        title: 'Likes',
        subtitle: 'per post',
        price: '7',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 2,
        sale: '',
        count: '100',
        title: 'Likes',
        subtitle: 'per post',
        price: '13',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 3,
        sale: '',
        count: '250',
        title: 'Likes',
        subtitle: 'per post',
        price: '24',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 4,
        sale: '',
        count: '500',
        title: 'Likes',
        subtitle: 'per post',
        price: '44',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 5,
        sale: '',
        count: '1000',
        title: 'Likes',
        subtitle: 'per post',
        price: '79',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 6,
        sale: '',
        count: '1500',
        title: 'Likes',
        subtitle: 'per post',
        price: '119',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
    ],
    'premium-quality': [
      {
        id: 7,
        sale: '',
        count: '50',
        title: 'Likes',
        subtitle: 'per post',
        price: '9',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 8,
        sale: '',
        count: '100',
        title: 'Likes',
        subtitle: 'per post',
        price: '19',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 9,
        sale: '',
        count: '250',
        title: 'Likes',
        subtitle: 'per post',
        price: '34',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 10,
        sale: '',
        count: '500',
        title: 'Likes',
        subtitle: 'per post',
        price: '64',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 11,
        sale: '',
        count: '1000',
        title: 'Likes',
        subtitle: 'per post',
        price: '119',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 12,
        sale: '',
        count: '1500',
        title: 'Likes',
        subtitle: 'per post',
        price: '189',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
    ],
  },
  comments: {
    'high-quality': [
      {
        id: 13,
        sale: '',
        count: '10',
        title: 'Comments',
        subtitle: 'per post',
        price: '11',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 14,
        sale: '',
        count: '25',
        title: 'Comments',
        subtitle: 'per post',
        price: '19',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 15,
        sale: '',
        count: '50',
        title: 'Comments',
        subtitle: 'per post',
        price: '34',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 16,
        sale: '',
        count: '100',
        title: 'Comments',
        subtitle: 'per post',
        price: '64',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 17,
        sale: '',
        count: '250',
        title: 'Comments',
        subtitle: 'per post',
        price: '124',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
    ],
    'premium-quality': [],
  },
  views: {
    'high-quality': [
      {
        id: 18,
        sale: '',
        count: '1000',
        title: 'Views',
        subtitle: 'per post',
        price: '8',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 19,
        sale: '',
        count: '2500',
        title: 'Views',
        subtitle: 'per post',
        price: '14',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 20,
        sale: '',
        count: '5000',
        title: 'Views',
        subtitle: 'per post',
        price: '24',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 21,
        sale: '',
        count: '10000',
        title: 'Views',
        subtitle: 'per post',
        price: '44',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 22,
        sale: '',
        count: '25000',
        title: 'Views',
        subtitle: 'per post',
        price: '79',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 23,
        sale: '',
        count: '50000',
        title: 'Views',
        subtitle: 'per post',
        price: '149',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
    ],
    'premium-quality': [
      {
        id: 24,
        sale: '',
        count: '1000',
        title: 'Views',
        subtitle: 'per post',
        price: '11',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 25,
        sale: '',
        count: '2500',
        title: 'Views',
        subtitle: 'per post',
        price: '17',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 26,
        sale: '',
        count: '5000',
        title: 'Views',
        subtitle: 'per post',
        price: '34',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 27,
        sale: '',
        count: '10000',
        title: 'Views',
        subtitle: 'per post',
        price: '49',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 28,
        sale: '',
        count: '25000',
        title: 'Views',
        subtitle: 'per post',
        price: '89',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
      {
        id: 29,
        sale: '',
        count: '50000',
        title: 'Views',
        subtitle: 'per post',
        price: '169',
        cents: '.99',
        inclusions: [
          'Up to 4 posts per day',
          '100% Secure & Private',
          '24/7 Support',
          'Premium Likes from Real Users',
          'Cancel Anytime',
        ],
      },
    ],
  },
}
