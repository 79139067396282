import { useContext, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import QuestionImg from '../assets/images/question.png'
import { AppContext } from '../Protected'

type FaqType = {
  title: string
  content: Function | string[]
}

const Help = () => {
  const { isDesktop } = useContext(AppContext)
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  return (
    <Box p={!isDesktop ? '5px 10px 120px 20px' : '0px 40px 0px 40px'}>
      <Grid container spacing={3} pt="10px">
        <Grid item xs={12} md={8}>
          <Box
            p={isDesktop ? 5 : 2}
            color="#fff"
            bgcolor="#4862FF"
            borderRadius="10px"
          >
            <Typography
              fontWeight={700}
              fontSize={isDesktop ? 32 : 28}
              lineHeight={1}
            >
              Search for a question
            </Typography>
            <Typography fontWeight={400} fontSize={16} mb={isDesktop ? 4 : 1}>
              Type your question or search keyword
            </Typography>
            <FormControl
              sx={{
                width: isDesktop ? '335px' : '100%',
                '.MuiOutlinedInput-root': {
                  bgcolor: '#fff',
                  borderRadius: '10px',
                  svg: {
                    ml: '5px',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  },
                  '&:hover': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  },
                },
              }}
            >
              <TextField
                placeholder="Start typing..."
                size="small"
                id="outlined-basic"
                variant="outlined"
                onChange={(e) => {}}
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      sx={{
                        fontSize: '20px',
                      }}
                    />
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Box
            pt="20px"
            sx={{
              '& .MuiAccordion-root': {
                marginBottom: '20px',
                borderRadius: '10px !important',
                border: '1px solid #E3E3E3',
                padding: '10px 5px',
                backgroundColor: '#fff',
                boxShadow: 'none',
                '&:before': {
                  height: '0px',
                },
                h2: {
                  fontSize: '20px',
                  fontWeight: '700',
                  color: '#281C58',
                  width: '100%',
                },
              },
              '& .MuiList-root': {
                '& .MuiListItemIcon-root': {
                  minWidth: '35px',
                  svg: {
                    fontSize: '17px',
                    color: '#00B67A',
                  },
                },
              },
            }}
          >
            {[
              {
                title: 'I did not receive my order, What do I do now?',
                content: () => (
                  <>
                    <Typography>
                      Please ensure the following criteria are met before
                      placing an order to secure a successful delivery.
                    </Typography>
                    <List dense={true}>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Your Instagram account is set to public." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="You have typed in the correct Instagram username." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="You have not changed your Instagram username before the order’s completion." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="The account or post, still exists and has not been deleted." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="The video follows Instagram’s guidelines and has not been restricted or removed." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Allow 24 hours for completion of order delivery." />
                      </ListItem>
                    </List>
                  </>
                ),
              },
              {
                title: 'Why are my followers dropping?',
                content: ['Paragraph 1....', 'Paragraph 2.....'],
              },
              {
                title: 'The followers I purchsed, can they be removed?',
                content: ['Paragraph 1....'],
              },
              {
                title: 'How do I stop follower replenishment?',
                content: ['Paragraph 1....'],
              },
            ].map(({ title, content }: FaqType, index: number) => {
              const key = `panel${index}`
              return (
                <Accordion
                  expanded={expanded === key}
                  onChange={handleChange(key)}
                >
                  <AccordionSummary
                    expandIcon={<CustomExpandIcon />}
                    aria-controls={`${key}bh-content`}
                    id={`${key}bh-header`}
                  >
                    <Typography variant="h2" flexShrink={0}>
                      {title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Array.isArray(content)
                      ? content.map((content: string, index: number) => {
                          const isLast = content.length === index + 1
                          return (
                            <>
                              <Typography key={`${content}${index}`}>
                                {content}
                              </Typography>
                              {!isLast && <br />}
                            </>
                          )
                        })
                      : content()}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            p={5}
            color="#000"
            bgcolor="#fff"
            borderRadius="10px"
            textAlign="center"
          >
            <img src={QuestionImg} alt="" />
            <Typography
              fontWeight={700}
              fontSize={22}
              lineHeight={1}
              mt="20px"
              mb="15px"
            >
              Do you have questions?
            </Typography>
            <Typography fontWeight={400} fontSize={16} mb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              malesuada placerat dapibus. Ut bibendum cursus dolor
            </Typography>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              sx={{
                ml: '10px',
                p: '10px',
                border: '1px solid #00B67A',
                color: '#fff',
                bgcolor: '#00B67A',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#00B67A',
                },
              }}
              onClick={() => {}}
            >
              Shoot a Direct Mail
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}
    >
      <AddIcon className="collapsIconWrapper" />
      <RemoveIcon sx={{ color: '#00B67A' }} className="expandIconWrapper" />
    </Box>
  )
}

export default Help
