import React, { useContext, useState, useEffect, ChangeEvent } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import Slide from '@mui/material/Slide'
import PercentIcon from '@mui/icons-material/Percent'
import { TransitionProps } from '@mui/material/transitions'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import TableWrapper from '../components/table-wrapper/tableWrapper'
import { AppContext } from '../Protected'
import CustomButton from '../components/customButton'
import { couponStatuses } from '../constants'
import CustomInput from '../components/customInput'
import CustomDate from '../components/customDate'
import { SearchContext } from '../contexts/search.context'
import { isNumber, numValChecker } from '../helpers/utils'
import moment from 'moment'
import {
  useAddCoupon,
  useDeleteCoupon,
  useGetCouponList,
  useUpdateCoupon,
} from '../services/coupon.service'
import { ICoupon } from '../types/coupon.types'
import { Delete, Edit } from '@mui/icons-material'

const tableColumns = [
  { key: 'name', label: 'Name' },
  {
    key: 'code',
    label: 'Code',
    customValue: (data: any) => (
      <Box
        borderRadius="10px"
        fontSize={14}
        fontWeight={600}
        color="#261F72"
        p="5px 10px"
        display="inline-block"
        sx={{ backgroundColor: '#F1F4FF' }}
      >
        {data?.code ?? ''}
      </Box>
    ),
  },
  { key: 'discount_percentage', label: 'Discount Amount' },
  {
    key: 'status',
    label: 'Status',
    customValue: (data: any) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            '& .status-icon': {
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              mr: 1,
            },
            '&.expired': {
              '& .status-icon': { backgroundColor: '#FF4848' },
            },
            '&.active': {
              '& .status-icon': { backgroundColor: '#27A857' },
            },
            '&.draft': {
              '& .status-icon': { backgroundColor: '#F8E21E' },
            },
            '&.scheduled': {
              '& .status-icon': { backgroundColor: '#67C8FF' },
            },
          }}
          className={data.status}
        >
          <div className="status-icon" />{' '}
          {(couponStatuses as any)[data.status] ?? ''}
        </Box>
      )
    },
  },
]

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CouponScreen = () => {
  const { isDesktop, setNotification } = useContext(AppContext)
  const { globalSearchVal, fireSearch, setSearchLabel, setFireSearch } =
    useContext(SearchContext)

  const [showCoupon, setShowAddCoupon] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [page, setPage] = useState(1)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [couponToDelete, setCouponToDelete] = useState<ICoupon | null>(null)
  const [sort, setSort] = useState('code')

  const [coupon, setCoupon] = useState<ICoupon>({
    name: '',
    discount_percentage: 0,
    code: '',
    start_date: '',
    end_date: '',
    limit: 0,
  })
  const [hasError, setHasError] = useState(false)

  const { data, refetch, isFetching } = useGetCouponList(
    `&per_page=15&page=${page}&search=${globalSearchVal}&sort_by=${sort}`
  )
  const { meta = {}, data: rows = [] } = data ?? {}

  const { isLoading: addingCoupon, mutate: add } = useAddCoupon()
  const { isLoading: updatingCoupon, mutate: update } = useUpdateCoupon()
  const { isLoading: deletingCoupon, mutate: deleteCoupon } = useDeleteCoupon()

  const isLoading = addingCoupon || updatingCoupon || deletingCoupon
  useEffect(() => {
    setTimeout(() => refetch(), 100)
  }, [page, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fireSearch) {
      if (page > 1) {
        return setPage(1)
      }
      refetch()
    }
  }, [fireSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetching) setFireSearch(false)
  }, [isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchLabel('Search Code')
  }, []) // eslint-disable-line

  const handleAddPackage = () => {
    setShowAddCoupon(true)
  }

  const handleSaveCoupon = () => {
    const isError =
      !coupon.name ||
      !coupon.discount_percentage ||
      !coupon.code
    setHasError(isError)

    if (!isError) {
      const data = {
        ...coupon,
        start_date: coupon?.start_date
          ? moment(coupon.start_date).format('YYYY-MM-DD')
          : '',
        end_date: coupon?.end_date
          ? moment(coupon.end_date).format('YYYY-MM-DD')
          : '',
      }
      if (isEdit) {
        update(data, {
          onSuccess: () => {
            refetch()
            setShowAddCoupon(false)
            setNotification('Successfully updated coupon.')
          },
        })
        return
      }
      add(data, {
        onSuccess: () => {
          refetch()
          setShowAddCoupon(false)
          setNotification('Successfully added coupon.')
        },
      })
    }
  }

  const handleConfirmDelete = () => {
    deleteCoupon(couponToDelete?.id ?? 0, {
      onSuccess: () => {
        refetch()
        setOpenDeleteModal(false)
        setNotification('Successfully deleted coupon.')
      },
    })
  }

  const onClose = (e: any = null, reason: string = '') => {
    if (!['escapeKeyDown', 'backdropClick'].includes(reason)) {
      setShowAddCoupon(false)
    }
  }

  const contextMenu = [
    {
      callBack: (data: ICoupon) => {
        setShowAddCoupon(true)
        setCoupon({
          ...data,
          start_date: data.start_date ? new Date(data.start_date) : '',
          end_date: data.end_date ? new Date(data.end_date) : '',
        })
        setIsEdit(true)
      },
      label: 'Edit Coupon',
      icon: <Edit />,
    },
    {
      callBack: (data: ICoupon) => {
        setOpenDeleteModal(true)
        setCouponToDelete(data)
      },
      label: 'Delete Coupon',
      icon: <Delete />,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      {isDesktop && showCoupon && (
        <Box p={!isDesktop ? '5px 10px 100px 20px' : '30px 40px 0px 40px'}>
          <Box bgcolor="#fff" borderRadius="10px">
            <Box p={3}>
              <CouponForm
                coupon={coupon}
                setCoupon={setCoupon}
                hasError={hasError}
              />
            </Box>
            <Box
              p={2}
              borderTop="1px solid #eee"
              display="flex"
              justifyContent="end"
            >
              <Button
                variant="text"
                sx={{ pl: 2, pr: 2 }}
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <CustomButton
                label={isEdit ? 'Update Coupon' : 'Create Coupon'}
                sx={{
                  p: '10px 15px!important',
                  fontSize: 14,
                  ml: 2,
                }}
                className="likester-button"
                onClick={handleSaveCoupon}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Dialog
        open={!isDesktop && showCoupon}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        disableEscapeKeyDown
        aria-describedby="add-coupon-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Add New Coupon
        </DialogTitle>
        <DialogContent>
          <CouponForm
            isDesktop={false}
            coupon={coupon}
            setCoupon={setCoupon}
            hasError={hasError}
          />
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column', pl: 2, pr: 2 }}>
          <CustomButton
            label={isEdit ? 'Update Coupon' : 'Create Coupon'}
            sx={{
              p: '10px 15px!important',
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            onClick={handleSaveCoupon}
            isLoading={isLoading}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={onClose}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDeleteModal(false)}
        disableEscapeKeyDown
        aria-describedby="add-coupon-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Delete Coupon Confirmation
        </DialogTitle>
        <DialogContent>
          <Box>
            Are you sure you want to delete coupon {couponToDelete?.name ?? ''}?
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: isDesktop ? 'row-reverse' : 'column',
            pl: 2,
            pr: 2,
          }}
        >
          <CustomButton
            label="Confirm"
            sx={{
              p: '10px 15px!important',
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            onClick={handleConfirmDelete}
            isLoading={isLoading}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={() => setOpenDeleteModal(false)}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <TableWrapper
        rows={rows}
        columns={tableColumns}
        contextMenu={contextMenu}
        withOptions
        title="Coupons"
        excludedHeaders={['name']}
        mobileHeader={(data: any) => (
          <Box display="flex" alignItems="center">
            {/* <CustomCheckbox checked={false} onChange={() => {}} /> */}
            <Typography ml={0} fontSize="16" color="#261F72">
              {data?.name ?? ''}
            </Typography>
          </Box>
        )}
        aditionalFilters={
          <Box ml={isDesktop ? 2 : 0} mt={isDesktop ? 0 : 2}>
            <CustomButton
              label="Add Coupon"
              sx={{
                p: isDesktop ? '5px 15px!important' : '10px 0px!important',
                fontSize: isDesktop ? 14 : 18,
              }}
              fullWidth={!isDesktop}
              className="likester-button"
              onClick={handleAddPackage}
            />
          </Box>
        }
        isLoading={isFetching}
        total={numValChecker(meta.total)}
        currentPage={numValChecker(meta.current_page)}
        pageCount={numValChecker(meta.last_page)}
        pageFrom={numValChecker(meta.from)}
        pageTo={numValChecker(meta.to)}
        onPageChange={setPage}
        sortByOptions={{
          status: 'Status',
          name: 'Name',
          code: 'Code',
        }}
        onSort={setSort}
        sort={sort}
      />
    </Box>
  )
}

const CouponForm = ({
  isDesktop = true,
  hasError = false,
  coupon,
  setCoupon,
}: {
  isDesktop?: boolean
  hasError?: boolean
  coupon: ICoupon
  setCoupon: Function
}) => {
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (
      ['limit', 'discount_percentage'].includes(name) &&
      !isNumber(value) &&
      value
    )
      return

    setCoupon({
      ...coupon,
      [name]: value,
    })
  }

  const onChangeDate = (value: Date, key: string) => {
    setCoupon({
      ...coupon,
      [key]: value,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={isDesktop ? 2 : 4}>
        <CustomInput
          label="Limit"
          value={coupon.limit}
          name="limit"
          placeholder="Number only"
          onChange={onChangeInput}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 8} height="55px">
        <CustomInput
          label="Name"
          value={coupon.name}
          name="name"
          placeholder="Type here"
          onChange={onChangeInput}
          required
          error={hasError && !coupon.name}
        />
      </Grid>
      <Grid item xs={isDesktop ? 3 : 12} zIndex={11}>
        <CustomDate
          label="Start Date"
          placeholderText="Start Date"
          selected={coupon.start_date}
          onChange={(date: Date) => onChangeDate(date, 'start_date')}
          selectsStart
          startDate={coupon.start_date}
          endDate={coupon.end_date}
        />
      </Grid>
      <Grid item xs={isDesktop ? 3 : 12} zIndex={10}>
        <CustomDate
          label="End Date"
          placeholderText="End Date"
          selected={coupon.end_date}
          onChange={(date: Date) => onChangeDate(date, 'end_date')}
          selectsEnd
          startDate={coupon.start_date}
          endDate={coupon.end_date}
          minDate={coupon.start_date}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <CustomInput
          label="Code"
          value={coupon.code}
          name="code"
          placeholder="Type here"
          onChange={onChangeInput}
          required
          error={hasError && !coupon.code}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <CustomInput
          label="Discount Percentage"
          value={coupon.discount_percentage}
          name="discount_percentage"
          placeholder="Number only"
          onChange={onChangeInput}
          InputProps={{
            endAdornment: <PercentIcon fill="#ccc" />,
          }}
          required
          error={hasError && !coupon.discount_percentage}
        />
      </Grid>
    </Grid>
  )
}

export default CouponScreen
