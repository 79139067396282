import React, { useEffect, useState, useContext } from 'react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material'
import { red } from '@mui/material/colors'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import LockIcon from '@mui/icons-material/Lock'
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled'
import { TransitionProps } from '@mui/material/transitions'

import TableWrapper from '../components/table-wrapper/tableWrapper'
import { useGetUserList } from '../services/user.service'
import { numValChecker } from '../helpers/utils'
import { SearchContext } from '../contexts/search.context'
import IconSkeleton from '../components/skeletons/icon.skeleton'
import TextSkeleton from '../components/skeletons/text.skeleton'
import { AppContext } from '../Protected'
import CustomButton from '../components/customButton'
import IUser from '../types/user.types'
import { useUpdateUserStatusMutation } from '../services/account.service'
import { userStatuses } from '../constants'
import { CheckCircle } from '@mui/icons-material'

const tableColumns = [
  {
    key: 'name',
    label: 'Full Name',
    align: 'center',
    customValue: (data: any) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{data.name}</Box>
      )
    },
  },
  { key: 'instagramId', label: 'Instagram ID', align: 'left' },
  { key: 'email', label: 'Email Address', align: 'right' },
  { key: 'ipAddress', label: 'IP Address', align: 'right' },
  // { key: 'bankName', label: 'Bank Name', align: 'right' },
  // { key: 'country', label: 'Country', align: 'right' },
  {
    key: 'status',
    label: 'Status',
    customValue: (data: any) => {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            '& .status-icon': {
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              mr: 1,
            },
            '&.banned, &.banned_ip': {
              '& .status-icon': { backgroundColor: '#FF4848' },
            },
            '&.active': {
              '& .status-icon': { backgroundColor: '#27A857' },
            },
            '&.suspended': {
              '& .status-icon': { backgroundColor: '#FF9548' },
            },
          }}
          className={data.status}
        >
          <div className="status-icon" />{' '}
          {(userStatuses as any)[data.status] ?? ''}
        </Box>
      )
    },
  },
]

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Customers = () => {
  const { globalSearchVal, fireSearch, setSearchLabel, setFireSearch } =
    useContext(SearchContext)

  const { isDesktop, setNotification } = useContext(AppContext)

  const [page, setPage] = useState(1)
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [action, setAction] = useState<[string, string] | null>(null)
  const [selecteduser, setSelectedUser] = useState<IUser | null>(null)
  const [sort, setSort] = useState('created_at')

  const { data, refetch, isFetching } = useGetUserList(
    `&per_page=15&page=${page}&search=${globalSearchVal}&sort_by=${sort}`
  )
  const { meta = {}, data: rows = [] } = data ?? {}

  const { isLoading, mutate: updateStatus } = useUpdateUserStatusMutation()

  useEffect(() => {
    setTimeout(() => refetch(), 100)
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fireSearch) {
      if (page > 1) {
        return setPage(1)
      }
      refetch()
    }
  }, [fireSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetching) setFireSearch(false)
  }, [isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchLabel('Search by Full Name or Instagram ID')
  }, []) // eslint-disable-line

  const handleAction = (action: [string, string], user: IUser) => {
    setAction(action)
    setSelectedUser(user)
    setIsActionModalOpen(true)
  }

  const handleConfirmAction = () => {
    if (selecteduser?.id) {
      updateStatus(
        { id: selecteduser?.id, action: (action ?? [])[0] ?? '' },
        {
          onSuccess: () => {
            refetch()
            setIsActionModalOpen(false)
            setNotification('Successfully updated status.')
          },
        }
      )
    }
  }

  const contextMenu = [
    {
      callBack: (user: IUser) => {
        handleAction(['activate', `Activate ${user.name}`], user)
      },
      label: 'Activate',
      icon: <CheckCircle />,
    },
    {
      callBack: (user: IUser) => {
        handleAction(['ban', `ban ${user.name} permanently`], user)
      },
      label: 'Ban Permanently',
      icon: <PersonRemoveIcon />,
    },
    {
      callBack: (user: IUser) => {
        handleAction(['suspend', `suspend ${user.name}`], user)
      },
      label: 'Suspend User',
      icon: <PauseCircleFilledIcon />,
    },
    {
      callBack: (user: IUser) => {
        handleAction(['ban_ip', `ban ${user.name}'s IP Address`], user)
      },
      label: 'Ban User IP Address',
      icon: <LockIcon />,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <TableWrapper
        rows={rows}
        columns={tableColumns}
        contextMenu={contextMenu}
        withOptions
        title="Customers"
        excludedHeaders={['name']}
        mobileHeader={(data: any = {}) => (
          <Box display="flex" alignItems="center">
            {/* <CustomCheckbox checked={false} onChange={() => {}} disabled /> */}
            {isFetching ? (
              <>
                <IconSkeleton sx={{ mr: 2 }} />
                <TextSkeleton width="100px" />
              </>
            ) : (
              <>
                <Avatar
                  sx={{ height: '37px', width: '37px', bgcolor: red[500] }}
                >
                  {(data['name'] ?? [])[0]}
                </Avatar>

                <Typography ml={1} fontSize="16" color="#261F72">
                  {data?.name ?? ''}
                </Typography>
              </>
            )}
          </Box>
        )}
        isLoading={isFetching}
        total={numValChecker(meta.total)}
        currentPage={numValChecker(meta.current_page)}
        pageCount={numValChecker(meta.last_page)}
        pageFrom={numValChecker(meta.from)}
        pageTo={numValChecker(meta.to)}
        onPageChange={setPage}
        sortByOptions={{
          created_at: 'Created At',
          name: 'Name',
          email: 'Email',
        }}
        onSort={setSort}
        sort={sort}
      />
      <Dialog
        open={isActionModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsActionModalOpen(false)}
        disableEscapeKeyDown
        aria-describedby="add-coupon-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Action Confirmation
        </DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to {(action ?? [])[1]}?</Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: isDesktop ? 'row-reverse' : 'column',
            pl: 2,
            pr: 2,
          }}
        >
          <CustomButton
            label="Confirm"
            sx={{
              p: '3px 15px!important',
              ml: 2,
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            onClick={handleConfirmAction}
            isLoading={isLoading}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={() => setIsActionModalOpen(false)}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Customers
