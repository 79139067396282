import { useQuery } from 'react-query'
import { apiResponseSimplify } from '../helpers/utils'
import API_INSTANCE from './api'
import endpoints from './endpoints'

/**
 * Get User List
 */
export const useGetUserList = (queryParams: string = '') => {
  return useQuery(
    ['user-list', queryParams],
    () => API_INSTANCE.get(`${endpoints.GET_USERS}?${queryParams}`),
    {
      enabled: false,
      // keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )
}
