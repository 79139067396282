export default interface IUser {
  id?: any | null
  name: string | null
  phone_number?: string
  status: string
  email: string
}

export const TYPE_SUPER_ADMIN = 'admin'
export const TYPE_WORKER = 'worker'
export const TYPE_CUSTOMER = 'customer'

export const USER_TYPES = [
  {
    key: 'customer',
    name: 'Customer',
  },
  {
    key: 'admin',
    name: 'Admin',
  },
  {
    key: 'worker',
    name: 'Worker',
  },
]

export const GENDERS = [
  {
    key: 1,
    name: 'Female',
  },
  {
    key: 2,
    name: 'Male',
  },
  {
    key: 3,
    name: 'Unspecified',
  },
]
