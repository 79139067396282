import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { Box } from '@mui/system'
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Button,
  Modal,
} from '@mui/material'
import { Navigate } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { getToken, useRegisterMutation } from '../services/auth.service'

import LoginBg from '../assets/images/login-bg.png'
import EmailConfirm from '../assets/images/email-confirmation.png'
import WavePopup from '../assets/images/wave-line-popup.png'
import Logo from '../assets/images/logo.png'
import WaveLine from '../assets/images/wave-line.png'
import LoginBanner from '../assets/images/login-banner.png'
import Testimony1 from '../assets/images/testimony1.png'
import Testimony2 from '../assets/images/testimony2.png'
import Testimony3 from '../assets/images/testimony3.png'
import GoogleLogo from '../assets/images/google-logo.png'
import { CheckScreenSize } from '../helpers/utils'
import CustomButton from '../components/customButton'
import CloseIcon from '@mui/icons-material/Close'

const Register = (props: any) => {
  const isAuthenticated = getToken() !== null

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const defaultErrors = {
    name: false,
    email: false,
    password: false,
    c_password: false,
  }

  const [errors, setErrors] = useState(defaultErrors)
  const [credential, setCredential] = useState({
    name: '',
    email: '',
    password: '',
    c_password: '',
  })

  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [redirectSuccess, setRedirectSuccess] = useState(false)

  const { isLoading, mutate: register } = useRegisterMutation()

  const LoginWithGoogle = () => {
    window.location.href = process.env.REACT_APP_BASE_URL + '/api/login/google'
    return
  }

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setCredential((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validate = () => {
    let dataFields = [
      {
        key: 'name',
        isValid: credential.name !== '',
      },
      {
        key: 'email',
        isValid: credential.email !== '',
      },
      {
        key: 'password',
        isValid: credential.password !== '',
      },
      {
        key: 'c_password',
        isValid:
          credential.c_password !== '' &&
          credential.c_password === credential.password,
      },
    ]

    return {
      data: dataFields,
      isValid: dataFields.every((data) => data.isValid),
    }
  }

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()

    const validation = validate()
    if (!validation.isValid) {
      setErrors(
        validation.data.reduce((prevField, field) => {
          return { ...prevField, [field.key]: !field.isValid }
        }, defaultErrors)
      )
      return
    }

    register(credential, {
      onSuccess: (data) => {
        if (data?.data) {
          setRegisterSuccess(true)
        }
        return
      },
    })
  }

  const handleClose = () => {
    setRegisterSuccess(false)
    setRedirectSuccess(true)
  }

  const isDesktop = CheckScreenSize('(min-width: 1200px)')
  const isMediumScreenMin = CheckScreenSize('(min-width: 900px)')
  const isMediumScreenMax = CheckScreenSize('(max-width: 1199px)')
  const isMediumScreen = isMediumScreenMin && isMediumScreenMax

  const isSmallestScreen = CheckScreenSize('(max-width: 599px)')

  const isSmallScreenMin = CheckScreenSize('(min-width: 600px)')
  const isSmallScreenMax = CheckScreenSize('(max-width: 899px)')
  const isSmallScreen =
    isSmallestScreen || (isSmallScreenMax && isSmallScreenMin)

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  if (redirectSuccess) {
    return <Navigate to="/login" />
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallestScreen ? '80%' : '500px',
    backgroundColor: '#fff',
    boxShadow: 24,
    borderRadius: '12px',
    '& img': {
      width: isSmallestScreen ? '100px' : 'auto',
    },
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: '#FFF18E',
          height: '100vh',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        data-testid="login-user-form"
      >
        {!isSmallScreen && (
          <Box
            sx={{
              width: '50%',
              backgroundImage: `url(${WaveLine})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 70%',
              backgroundPosition: 'bottom right',
              zIndex: 1,
              position: 'relative',
              '.logo': {
                ml: '100px',
                mt: '80px',
              },
              '.banner': {
                position: 'absolute',
                bottom: 0,
                right: isMediumScreen ? 0 : -130,
                height: '80%',
              },
              '.object': {
                position: 'absolute',
                objectFit: 'contain',
              },
              '.testimony': {
                right: -100,
                '&2': {
                  top: 200,
                },
                '&1': {
                  bottom: 120,
                  right: -155,
                },
                '&3': {
                  bottom: 30,
                },
              },
            }}
          >
            <img className="logo" src={Logo} alt="Logo" />
            <img className="banner" src={LoginBanner} alt="Banner" />
            <img
              className="object testimony testimony1"
              src={Testimony1}
              alt="testimony1"
              data-value={isSmallScreen ? '0' : '-5'}
            />
            <img
              className="object testimony testimony2"
              src={Testimony2}
              alt="testimony2"
              data-value={isSmallScreen ? '0' : '8'}
            />
            <img
              className="object testimony  testimony3"
              src={Testimony3}
              alt="testimony3"
              data-value={isSmallScreen ? '0' : '3'}
            />
          </Box>
        )}
        <Box
          sx={{
            width: !isSmallScreen ? '50%' : '100%',
            backgroundImage: `url(${LoginBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: !isSmallScreen ? 'unset' : '20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              margin: isSmallScreen ? '5px' : 'unset',
              mb: !isDesktop ? '40px' : 'unset',
              h4: {
                textAlign: 'center',
                fontSize: 36,
                fontWeight: 'bold',
                padding: 0,
                color: '#000',
                mb: '10px',
              },
              p: {
                textAlign: 'center',
                fontSize: 15,
                fontWeight: 400,
                color: '#7e7e7e',
                margin: 0,
                padding: 0,
              },
              '.sub-title': {
                fontSize: 18,
                color: '#000',
                mb: '40px',
              },
              '.login-with-google': {
                background: ' #ffffff',
                border: '1px solid #dedede',
                padding: '6px 20px',
                textTransform: 'none',
                color: '#000000',
                mb: '25px',
                '&:hover': {
                  background: ' #ffffff',
                },
                span: {
                  mt: '4px',
                },
                img: {
                  mr: '8px',
                  width: '22px',
                },
              },
            }}
          >
            <Box>
              <h4>Get Started With Likester!</h4>
              <div className="sub-title">Getting started is easy</div>
              <div>
                <Button className="login-with-google" onClick={LoginWithGoogle}>
                  <img src={GoogleLogo} alt="Login with google" />
                  <span>Google</span>
                </Button>
              </div>
            </Box>
            <Box
              sx={{
                padding: '0 5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& div': { width: '100%' },
                '& div.line': {
                  borderTop: '1px solid rgb(38 31 114 / 26%)',
                },
                '& div.label': {
                  textAlign: 'center',
                  fontSize: '13px',
                  color: '#000',
                },
              }}
            >
              <div className="line" />
              <div className="label">Or continue with</div>
              <div className="line" />
            </Box>
            <Box
              component="form"
              className="login-form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              sx={{
                margin: '0 auto',
                width: !isSmallestScreen ? '400px' : 'unset',
                mt: isSmallestScreen ? '16px!important' : '10px',
                '.MuiInputBase-root': {
                  bgcolor: 'white',
                  borderRadius: '10px',
                },
                '& input': {
                  fontSize: '14px',
                  padding: '25px 14px 24px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '& .MuiFormControl-root': {
                  mt: '10px',
                },
              }}
            >
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.name}
              >
                <OutlinedInput
                  fullWidth
                  placeholder="Full Name"
                  id="fullName"
                  type="text"
                  onChange={onChange('name')}
                />
              </FormControl>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.email}
              >
                <OutlinedInput
                  fullWidth
                  placeholder="Email"
                  id="email"
                  type="email"
                  onChange={onChange('email')}
                />
              </FormControl>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.password}
              >
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  id="password"
                  onChange={onChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        data-testid="password-visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.c_password}
              >
                <OutlinedInput
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  id="confirmPassword"
                  onChange={onChange('c_password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                        data-testid="confirm-password-visibility"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Grid
                container
                sx={{
                  overflow: 'auto',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: isSmallestScreen ? 'column' : 'row',
                }}
                justifyContent="flex-start"
              >
                <Grid sx={{ width: '100%' }}>
                  <Box
                    mt={isSmallestScreen ? '16px!important' : ''}
                    className="login-action"
                  >
                    <CustomButton
                      label="Create Account"
                      type="submit"
                      isLoading={isLoading}
                      sx={{
                        padding: '13px 55px!important',
                        width: '100%',
                      }}
                      className="likester-button"
                    />
                  </Box>
                </Grid>
                <Box
                  sx={{
                    mt: '20px',
                    textAlign: 'center',
                    width: '100%',
                    color: '#000',
                    a: {
                      textDecoration: 'none',
                      color: '#2A66FF',
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <p>
                    Have an account? <a href="/login">Sign In!</a>
                  </p>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={registerSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              color: '#281C58',
              fontWeight: '700',
              fontSize: '32px',
              backgroundImage: 'linear-gradient(#eeccc0, #f595ea)',
              borderRadius: '12px 12px 0 0 ',
              textAlign: 'center',
              '& img': {
                marginTop: '-115px',
              },
              '& .wave': {
                p: 4,
                backgroundImage: `url(${WavePopup})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
              },
              '& svg': {
                color: '#fff',
                fontSize: isSmallestScreen ? '30px' : '45px',
                position: 'absolute',
                right: isSmallestScreen ? '-23px' : '-27px',
                top: isSmallestScreen ? '-20px' : '-25px',
                backgroundColor: '#3791F0',
                borderRadius: '35px',
                padding: '5px',
                cursor: 'pointer',
              },
            }}
          >
            <div className="wave">
              <CloseIcon onClick={handleClose} />
              <img src={EmailConfirm} alt="" />
              <br />
              <br />
              Verify Your Email Address
            </div>
          </Box>
          <Box
            sx={{
              p: 3,
              color: '#000000;',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              textAlign: 'center',
              '& a': {
                color: '#FF4EA3',
              },
              '& p': {
                textAlign: 'center',
                fontSize: '13px',
              },
            }}
          >
            You've entered{' '}
            <a href={credential?.email}>{credential?.email ?? ''}</a> as the
            email address for your account. Please verify this email address by
            clicking the button below.
            <br />
            <br />
            <Box
              sx={{
                '& a': {
                  backgroundColor: '#00B67A',
                  borderRadius: ' 10px',
                  fontWeight: '500',
                  fontSize: '18px',
                  boxShadow: 'none',
                  // padding: '10px 20px',
                  color: '#fff',
                  textTransform: 'capitalize',
                  textDecoration: 'none',
                  padding: '20px 60px!important',
                  width: 'auto',
                },
              }}
            >
              <a
                href="https://mail.google.com/mail/u/0/#inbox"
                className="likester-button"
                rel="noreferrer"
                target="_blank"
              >
                Verify Your Email
              </a>
            </Box>
            <br />
            <br />
            <p>
              Questions? Email us at{' '}
              <a href="mailto: likestersupport@likester.com">
                likestersupport@likester.com
              </a>
            </p>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Register
