import { useState, createContext } from 'react'
import { AxiosError } from 'axios'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  QueryClientProvider,
  QueryClient,
  QueryCache,
  MutationCache,
} from 'react-query'
import { AlertColor } from '@mui/material'
import { ReactQueryDevtools } from 'react-query/devtools'
import './App.scss'
import ProtectedRoute from './Protected'
import Notification from './components/notification'
import { getErrorMessage } from './helpers/utils'
import routes from './routes'

type NotificationProps = {
  message: string | null
  type: AlertColor
  onClose?: Function
}

type PublicContextProps = {
  setNotification: Function
}

export const PublicContext = createContext({} as PublicContextProps)

function App() {
  const [notification, setNotification] = useState<NotificationProps | null>(
    null
  )
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (err) => {
        const errr = err as AxiosError
        handleNotification(getErrorMessage(errr), 'error')
      },
    }),
    mutationCache: new MutationCache({
      onError: (err) => {
        const errr = err as AxiosError
        handleNotification(getErrorMessage(errr), 'error')
      },
    }),
  })

  const handleNotification = (
    message: string,
    type: AlertColor = 'success'
  ) => {
    setNotification(!message ? null : { message, type })
  }

  const theme = createTheme({
    typography: {
      fontFamily: 'Proxima Nova',
      fontSize: 14,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={`route-${index}`}
                  path={route.path}
                  element={
                    route.isProtected ? (
                      <ProtectedRoute
                        Outlet={route.component}
                        setNotification={handleNotification}
                      />
                    ) : (
                      <PublicContext.Provider
                        value={{
                          setNotification: handleNotification,
                        }}
                      >
                        <>{route.component}</>
                      </PublicContext.Provider>
                    )
                  }
                />
              )
            })}
          </Routes>
        </Router>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
      {Boolean(notification) && (
        <Notification
          open={Boolean(notification)}
          severity={notification?.type}
          message={
            notification?.message ??
            'There is something wrong with your current session. Please logout and log back in.'
          }
          onClose={handleNotification}
        />
      )}
    </ThemeProvider>
  )
}

export default App
