import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

type SwitchTypes = {
  checked: boolean
  label?: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined
  customStyles?: object
  onChange?: (value: any) => void
  disabled?: boolean
}

export default function CustomSwitch(props: SwitchTypes) {
  return (
    <FormGroup sx={{ ...(props.customStyles ?? {}) }}>
      <FormControlLabel
        control={
          <Switch
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
            sx={{
              '.Mui-checked': {
                color: '#fff!important',
                '.MuiSwitch-thumb': {
                  boxShadow:
                    '0px 3px 1px 0px rgba(0,0,0,0.2), 0px 1px 1px 2px rgba(0,0,0,0.14), 0px 1px 3px 1px rgba(0,0,0,0.12)!important',
                },
              },
              '.MuiSwitch-track': {
                bgcolor: '#a9a1a1!important',
              },
              '.Mui-checked+.MuiSwitch-track': {
                bgcolor: '#21A076!important',
                opacity: '1!important',
              },
            }}
          />
        }
        onChange={props.onChange}
        labelPlacement={props?.labelPlacement ?? 'end'}
        label={props.label ?? ''}
        sx={{
          mr: '0!important',
          '.MuiFormControlLabel-label': {
            fontSize: 14,
          },
        }}
      />
    </FormGroup>
  )
}
