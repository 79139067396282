import { useQuery } from 'react-query'
import API_INSTANCE from './api'

/**
 * Get File
 */
export const useGetFile = (url: string = '') => {
  return useQuery(
    ['file', url],
    async () =>
      await API_INSTANCE.get(url, {
        responseType: 'blob',
      }),
    {
      enabled: false,
      select: (response) => {
        return URL.createObjectURL(response.data)
      },
    }
  )
}
