import { Avatar, Box, Card, Chip, Grid, Stack, Typography } from '@mui/material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'

export default function Statistics(props: any) {
  const { statistics } = props
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{
          p: '7px 15px 15px',
          borderRadius: '10px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            p: '10px 30px',
          }}
        >
          <Stack>
            <Typography
              color="#D02476"
              fontSize={13}
              fontWeight={600}
              sx={{ textTransform: 'uppercase' }}
            >
              {statistics.title}
            </Typography>
            <Typography color="#261F72" fontSize={24} fontWeight={700}>
              ${statistics.amount.toLocaleString()}
            </Typography>
            <Box color="#261F72" fontSize={12} fontWeight={400}>
              <Chip
                sx={{
                  bgcolor: '#05CD99',
                  borderRadius: '2px',
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
                label={statistics.percentage}
              />{' '}
              {statistics.label}
            </Box>
          </Stack>
          <Stack>
            <Avatar sx={{ bgcolor: '#ffecf5' }}>
              <LeaderboardIcon sx={{ color: '#FF4EA3' }} />
            </Avatar>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  )
}
