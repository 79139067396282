import { ChangeEvent, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@mui/material'
import { Navigate } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { getToken, useResetPasswordMutation } from '../services/auth.service'

import LoginBg from '../assets/images/login-bg.png'
import Logo from '../assets/images/logo.png'
import WaveLine from '../assets/images/wave-line.png'
import LoginBanner from '../assets/images/login-banner.png'
import Testimony1 from '../assets/images/testimony1.png'
import Testimony2 from '../assets/images/testimony2.png'
import Testimony3 from '../assets/images/testimony3.png'
import { CheckScreenSize } from '../helpers/utils'
import CustomButton from '../components/customButton'
import { PublicContext } from '../App'

const ResetPassword = (props: any) => {
    const { setNotification } = useContext(PublicContext)
    const isAuthenticated = getToken() !== null

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams?.get('token')
    const email = queryParams?.get('email')

    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const defaultErrors = {
        email: false,
        password: false,
        password_confirmation: false,
    }
    const [errors, setErrors] = useState(defaultErrors)
    const [credential, setCredential] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
    })

    const { isLoading, mutate: reset } = useResetPasswordMutation()

    const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setCredential((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(() => {
        if (token && email) {
            setCredential({
                ...credential,
                email: email,
                token: token,
            })
        }
    }, [token, email])

    const validate = () => {
        let dataFields = [
            {
                key: 'email',
                isValid: credential.email !== '',
            },
            {
                key: 'password',
                isValid: credential.password !== '',
            },
            {
                key: 'password_confirmation',
                isValid:
                    credential.password_confirmation !== '' &&
                    credential.password_confirmation === credential.password,
            },
        ]

        return {
            data: dataFields,
            isValid: dataFields.every((data) => data.isValid),
        }
    }

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validate()
        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrors)
            )
            return
        }

        reset(credential, {
            onSuccess: (data) => {
                if (data?.data) {
                    setNotification('Reset password successful!! Please login using your new password.', 'success')
                }
                return
            },
            onError: (error: any) => {
                if (error?.response?.status === 400) {
                  setNotification('Token expired', 'error')
                }
            },
        })
    }

    const isDesktop = CheckScreenSize('(min-width: 1200px)')
    const isMediumScreenMin = CheckScreenSize('(min-width: 900px)')
    const isMediumScreenMax = CheckScreenSize('(max-width: 1199px)')
    const isMediumScreen = isMediumScreenMin && isMediumScreenMax
    const isSmallestScreen = CheckScreenSize('(max-width: 599px)')
    const isSmallScreenMin = CheckScreenSize('(min-width: 600px)')
    const isSmallScreenMax = CheckScreenSize('(max-width: 899px)')
    const isSmallScreen =
        isSmallestScreen || (isSmallScreenMax && isSmallScreenMin)

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Box
                sx={{
                    bgcolor: '#FFF18E',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                data-testid="login-user-form"
            >
                {!isSmallScreen && (
                    <Box
                        sx={{
                            width: '50%',
                            backgroundImage: `url(${WaveLine})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 70%',
                            backgroundPosition: 'bottom right',
                            zIndex: 1,
                            position: 'relative',
                            '.logo': {
                                ml: '100px',
                                mt: '80px',
                            },
                            '.banner': {
                                position: 'absolute',
                                bottom: 0,
                                right: isMediumScreen ? 0 : -130,
                                height: '80%',
                            },
                            '.object': {
                                position: 'absolute',
                                objectFit: 'contain',
                            },
                            '.testimony': {
                                right: -100,
                                '&2': {
                                    top: 200,
                                },
                                '&1': {
                                    bottom: 120,
                                    right: -155,
                                },
                                '&3': {
                                    bottom: 30,
                                },
                            },
                        }}
                    >
                        <img className="logo" src={Logo} alt="Logo" />
                        <img className="banner" src={LoginBanner} alt="Banner" />
                        <img
                            className="object testimony testimony1"
                            src={Testimony1}
                            alt="testimony1"
                            data-value={isSmallScreen ? '0' : '-5'}
                        />
                        <img
                            className="object testimony testimony2"
                            src={Testimony2}
                            alt="testimony2"
                            data-value={isSmallScreen ? '0' : '8'}
                        />
                        <img
                            className="object testimony  testimony3"
                            src={Testimony3}
                            alt="testimony3"
                            data-value={isSmallScreen ? '0' : '3'}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        width: !isSmallScreen ? '50%' : '100%',
                        backgroundImage: `url(${LoginBg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        padding: !isSmallScreen ? 'unset' : '20px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            margin: isSmallScreen ? '5px' : 'unset',
                            mb: !isDesktop ? '40px' : 'unset',
                            h4: {
                                textAlign: 'center',
                                fontSize: 36,
                                fontWeight: 'bold',
                                padding: 0,
                                color: '#000',
                                mb: '10px',
                            },
                            p: {
                                textAlign: 'center',
                                fontSize: 15,
                                fontWeight: 400,
                                color: '#7e7e7e',
                                margin: 0,
                                padding: 0,
                            },
                            '.sub-title': {
                                fontSize: 18,
                                color: '#000',
                                mb: '40px',
                            },
                            '.login-with-google': {
                                background: ' #ffffff',
                                border: '1px solid #dedede',
                                padding: '6px 20px',
                                textTransform: 'none',
                                color: '#000000',
                                mb: '25px',
                                '&:hover': {
                                    background: ' #ffffff',
                                },
                                span: {
                                    mt: '4px',
                                },
                                img: {
                                    mr: '8px',
                                    width: '22px',
                                },
                            },
                        }}
                    >
                        <Box>
                            <h4>Reset Your Password</h4>
                            <div className="sub-title">Please fill your new details  to reset your password.</div>
                        </Box>
                        <Box
                            component="form"
                            className="login-form"
                            noValidate
                            autoComplete="off"
                            onSubmit={onSubmit}
                            sx={{
                                margin: '0 auto',
                                width: !isSmallestScreen ? '400px' : 'unset',
                                mt: isSmallestScreen ? '16px!important' : '10px',
                                '.MuiInputBase-root': {
                                    bgcolor: 'white',
                                    borderRadius: '10px',
                                },
                                '& input': {
                                    fontSize: '14px',
                                    padding: '25px 14px 24px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                                '& .MuiFormControl-root': {
                                    mt: '10px',
                                },
                            }}
                        >
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={errors.email}
                            >
                                <OutlinedInput
                                    fullWidth
                                    placeholder="Enter Email"
                                    id="email"
                                    type="email"
                                    onChange={onChange('email')}
                                />
                            </FormControl>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={errors.password}
                            >
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="New Password"
                                    id="password"
                                    onChange={onChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                data-testid="password-visibility"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={errors.password_confirmation}
                            >
                                <OutlinedInput
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm New Password"
                                    id="confirmPassword"
                                    onChange={onChange('password_confirmation')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple
                                                aria-label="toggle confirm password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                edge="end"
                                                data-testid="confirm-password-visibility"
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Grid
                                container
                                sx={{
                                    overflow: 'auto',
                                    marginTop: '20px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: isSmallestScreen ? 'column' : 'row',
                                }}
                                justifyContent="flex-start"
                            >
                                <Grid sx={{ width: '100%' }}>
                                    <Box
                                        mt={isSmallestScreen ? '16px!important' : ''}
                                        className="login-action"
                                    >
                                        <CustomButton
                                            label="Reset Password"
                                            type="submit"
                                            isLoading={isLoading}
                                            sx={{
                                                padding: '13px 55px!important',
                                                width: '100%',
                                            }}
                                            className="likester-button"
                                        />
                                    </Box>
                                </Grid>
                                <Box
                                    sx={{
                                        mt: '20px',
                                        textAlign: 'center',
                                        width: '100%',
                                        color: '#000',
                                        a: {
                                            textDecoration: 'none',
                                            color: '#2A66FF',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                >
                                    <p>
                                        <a href="/login">Cancel, go back to login</a>
                                    </p>
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ResetPassword
