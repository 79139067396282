import React, { useContext, useState, ChangeEvent, useEffect } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import TableWrapper from '../components/table-wrapper/tableWrapper'
import { AppContext } from '../Protected'
import CustomButton from '../components/customButton'
import CustomInput from '../components/customInput'
import CustomSelect from '../components/customSelect'
import { SubscriptionTypesLabel } from '../types/subscription.types'
import { IPackage } from '../types/package.types'
import {
  useAddPackage,
  useDeletePackage,
  useGetPackageList,
  useUpdatePackge,
} from '../services/package.service'
import { useOptions } from '../hooks/useOptions'
import { Services } from '../types/options.types'
import { isNumOrDecimal, numValChecker } from '../helpers/utils'
import { SearchContext } from '../contexts/search.context'

const tableColumns = [
  {
    key: 'service',
    label: 'Service Type',
    customValue: (row: IPackage) => (Services as any)[row?.service ?? ''],
  },
  {
    key: 'quantity',
    label: 'Amount of Service',
    customValue: (row: IPackage) => {
      let serviceLabel = SubscriptionTypesLabel.likes
      if (row?.service?.includes('views')) {
        serviceLabel = SubscriptionTypesLabel.views
      }
      if (row?.service?.includes('comments')) {
        serviceLabel = SubscriptionTypesLabel.comments
      }
      return `${row?.quantity} ${serviceLabel}`
    },
  },
  { key: 'rate', label: 'Price' },
  // {
  //   key: 'status',
  //   label: 'Status',
  //   customValue: (data: any) => {
  //     return (
  //       <Box
  //         sx={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           fontSize: '14px',
  //           '& .status-icon': {
  //             height: '8px',
  //             width: '8px',
  //             borderRadius: '50%',
  //             mr: 1,
  //           },
  //           '&.cancelled': {
  //             '& .status-icon': { backgroundColor: '#FF4848' },
  //           },
  //           '&.active': {
  //             '& .status-icon': { backgroundColor: '#27A857' },
  //           },
  //           '&.paused': {
  //             '& .status-icon': { backgroundColor: '#FF9548' },
  //           },
  //         }}
  //         className={data.status}
  //       >
  //         <div className="status-icon" /> {(statuses as any)[data.status] ?? ''}
  //       </Box>
  //     )
  //   },
  // },
]

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PackageScreen = () => {
  const { isDesktop, setNotification } = useContext(AppContext)
  const { globalSearchVal, fireSearch, setSearchLabel, setFireSearch } =
    useContext(SearchContext)

  const [showAddPackage, setShowAddPackage] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [sort, setSort] = useState('-created_at')
  const [page, setPage] = useState(1)
  const [pack, setPackage] = useState<IPackage>({
    service: '',
    quantity: 0,
    rate: 0,
  })
  const [hasError, setHasError] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [packageToDelete, setPackageToDelete] = useState<IPackage | null>(null)

  const { isLoading: addingPack, mutate: add } = useAddPackage()
  const { isLoading: updatingPack, mutate: update } = useUpdatePackge()
  const { isLoading: deletingPackage, mutate: deletePackage } =
    useDeletePackage()
  const { data, refetch, isFetching } = useGetPackageList(
    `per_page=15&page=${page}&search=${globalSearchVal}&sort_by=${sort}`
  )

  const { meta = {}, data: rows = [] } = data ?? {}

  useEffect(() => {
    setTimeout(() => refetch(), 100)
  }, [page, sort]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fireSearch) {
      if (page > 1) {
        return setPage(1)
      }
      refetch()
    }
  }, [fireSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetching) setFireSearch(false)
  }, [isFetching]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSearchLabel('Search Package')
  }, []) // eslint-disable-line

  const handleAddPackage = () => {
    setShowAddPackage(true)
  }

  const handleSavePackage = () => {
    const isError = !pack.service || !pack.quantity || !pack.rate
    setHasError(isError)

    if (!isError) {
      const data = {
        ...pack,
      }
      if (isEdit) {
        update(data, {
          onSuccess: () => {
            refetch()
            onClose()
            setNotification('Successfully updated package.')
          },
        })
        return
      }

      add(data, {
        onSuccess: () => {
          onClose()
          refetch()
          setNotification('Successfully added package.')
        },
      })
    }
  }

  const handleConfirmDelete = () => {
    deletePackage(packageToDelete?.id ?? 0, {
      onSuccess: () => {
        refetch()
        setOpenDeleteModal(false)
        setNotification('Successfully deleted package.')
      },
    })
  }

  const onClose = () => {
    setShowAddPackage(false)
  }

  const contextMenu = [
    {
      callBack: (data: IPackage) => {
        setShowAddPackage(true)
        const newData = { ...data }
        delete newData.created_at
        delete newData.updated_at
        setPackage(newData)
        setIsEdit(true)
      },
      label: 'Edit Package',
      icon: <DriveFileRenameOutlineIcon />,
    },
    {
      callBack: (data: IPackage) => {
        setOpenDeleteModal(true)
        setPackageToDelete(data)
      },
      label: 'Delete Package',
      icon: <DeleteForeverIcon />,
    },
  ]

  const isLoading = addingPack || updatingPack || deletingPackage

  return (
    <Box sx={{ width: '100%' }}>
      {isDesktop && showAddPackage && (
        <Box p={!isDesktop ? '5px 10px 100px 20px' : '30px 40px 0px 40px'}>
          <Box bgcolor="#fff" borderRadius="10px">
            <Box p={3}>
              <PackageForm
                pack={pack}
                hasError={hasError}
                setPackage={setPackage}
              />
            </Box>
            <Box
              p={2}
              borderTop="1px solid #eee"
              display="flex"
              justifyContent="end"
            >
              <Button
                variant="text"
                sx={{ pl: 2, pr: 2 }}
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <CustomButton
                label={`${isEdit ? 'Update' : 'Create'} Package`}
                sx={{
                  p: '10px 15px!important',
                  fontSize: 14,
                  ml: 2,
                }}
                className="likester-button"
                onClick={handleSavePackage}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </Box>
      )}
      <TableWrapper
        rows={rows}
        columns={tableColumns}
        contextMenu={contextMenu}
        withOptions
        title="Packages"
        mobileHeader={(row: any) => {
          let serviceLabel = SubscriptionTypesLabel.likes
          if (row?.service?.includes('views')) {
            serviceLabel = SubscriptionTypesLabel.views
          }
          if (row?.service?.includes('comments')) {
            serviceLabel = SubscriptionTypesLabel.comments
          }
          return (
            <Box display="flex" alignItems="center">
              {/* <CustomCheckbox checked={false} onChange={() => {}} /> */}
              <Typography ml={0} fontSize="16" color="#261F72">
                {(Services as any)[row?.service ?? '']} - {row?.quantity}{' '}
                {serviceLabel}
              </Typography>
            </Box>
          )
        }}
        aditionalFilters={
          <Box ml={isDesktop ? 2 : 0} mt={isDesktop ? 0 : 2}>
            <CustomButton
              label="Add Package"
              sx={{
                p: isDesktop ? '5px 15px!important' : '10px 0px!important',
                fontSize: isDesktop ? 14 : 18,
              }}
              fullWidth={!isDesktop}
              className="likester-button"
              onClick={handleAddPackage}
            />
          </Box>
        }
        sortByOptions={{
          '-created_at': 'Created at',
          service: 'Service Type',
          quantity: 'Amount of Service',
          rate: 'Price',
        }}
        isLoading={isFetching}
        onSort={setSort}
        sort={sort}
        onPageChange={setPage}
        total={numValChecker(meta.total)}
        currentPage={numValChecker(meta.current_page)}
        pageCount={numValChecker(meta.last_page)}
        pageFrom={numValChecker(meta.from)}
        pageTo={numValChecker(meta.to)}
      />
      <Dialog
        open={!isDesktop && showAddPackage}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="add-package-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Add New Package
        </DialogTitle>
        <DialogContent>
          <PackageForm
            isDesktop={false}
            pack={pack}
            hasError={hasError}
            setPackage={setPackage}
          />
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column', pl: 2, pr: 2 }}>
          <CustomButton
            label={`${isEdit ? 'Update' : 'Create'} Package`}
            sx={{
              p: '10px 15px!important',
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            isLoading={isLoading}
            onClick={handleSavePackage}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={onClose}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDeleteModal(false)}
        disableEscapeKeyDown
        aria-describedby="add-coupon-dialog"
      >
        <DialogTitle
          textAlign="center"
          color="#261F72"
          fontSize="22px"
          fontWeight="700"
        >
          Delete Package Confirmation
        </DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to delete selected package?</Box>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: isDesktop ? 'row-reverse' : 'column',
            pl: 2,
            pr: 2,
          }}
        >
          <CustomButton
            label="Confirm"
            sx={{
              p: '10px 15px!important',
              fontSize: 14,
            }}
            className="likester-button"
            fullWidth
            onClick={handleConfirmDelete}
            isLoading={isLoading}
          />
          <Button
            variant="text"
            sx={{ pl: 2, pr: 2 }}
            onClick={() => setOpenDeleteModal(false)}
            fullWidth
            disabled={isLoading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

type PackageTypes = {
  isDesktop?: boolean
  pack: IPackage
  hasError?: boolean
  setPackage?: Function
}

const PackageForm = ({
  isDesktop = true,
  pack,
  hasError = false,
  setPackage = () => {},
}: PackageTypes) => {
  const { options = {} } = useOptions()

  const serviceOptions = Services

  let serviceLabel = SubscriptionTypesLabel.likes
  if (pack?.service?.includes('views')) {
    serviceLabel = SubscriptionTypesLabel.views
  }
  if (pack?.service?.includes('comments')) {
    serviceLabel = SubscriptionTypesLabel.comments
  }
  const packageOptions = (options?.packages ?? [])
    .filter((p: IPackage) => p?.service === pack?.service)
    .reduce((acc: any, p: IPackage) => {
      return {
        ...acc,
        [p?.quantity]: `${p?.quantity} ${serviceLabel}`,
      }
    }, {})

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'rate' && !isNumOrDecimal(value) && value) return

    setPackage({
      ...pack,
      [name]: value,
    })
  }

  const onChangeSelect = (
    event: React.ChangeEvent<{ value: string }>,
    type: string
  ) => {
    let newPack = {
      ...pack,
      [type]: event.target.value,
    }
    if (type === 'service') {
      newPack.quantity = 0
    }
    setPackage(newPack)
  }

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={isDesktop ? 2 : 5}>
        <CustomInput label="Package No." value="100" />
      </Grid>
      <Grid item xs={isDesktop ? 2 : 7} height="55px">
        <CustomSelect
          options={SubscriptionTypesLabel}
          label="Service Type"
          value="likes"
          bRadius="10px"
          margin="0px"
          onChange={() => {}}
        />
      </Grid> */}
      <Grid item xs={isDesktop ? 4 : 12}>
        <CustomSelect
          options={serviceOptions}
          label="Service Type"
          bRadius="10px"
          margin="0px"
          value={pack?.service}
          required
          onChange={(event: React.ChangeEvent<{ value: string }>) =>
            onChangeSelect(event, 'service')
          }
          error={hasError && !pack?.service}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <CustomSelect
          options={packageOptions}
          label={`Amount of ${serviceLabel}`}
          value={pack?.quantity}
          bRadius="10px"
          margin="0px"
          required
          onChange={(event: React.ChangeEvent<{ value: string }>) =>
            onChangeSelect(event, 'quantity')
          }
          error={hasError && !pack?.quantity}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <CustomInput
          label="Price"
          value={pack?.rate}
          required
          name="rate"
          InputProps={{
            endAdornment: <AttachMoneyIcon fill="#ccc" />,
          }}
          onChange={onChangeInput}
          error={hasError && !pack?.rate}
        />
      </Grid>
      {/* <Grid item xs={isDesktop ? 2 : 12}>
        <CustomInput label="Discounted Price" value="20%" />
      </Grid> */}
      {/* <Grid item xs={isDesktop ? 2 : 12}>
        <CustomSelect
          options={{
            active: 'Active',
            paused: 'Paused',
            cancelled: 'Cancelled',
          }}
          label="Status"
          value="active"
          bRadius="10px"
          margin="0px"
          onChange={() => {}}
        />
      </Grid> */}
    </Grid>
  )
}

export default PackageScreen
