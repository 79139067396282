import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

interface SelectProps {
  label: string
  options: Object
  value: any
  onChange: any
  bRadius?: string
  margin?: string
  required?: boolean
  error?: boolean
}

export default function CustomSelect(props: SelectProps) {
  const {
    label,
    onChange,
    value,
    options,
    bRadius = '5px',
    margin = '5px 0px',
    ...rest
  } = props
  return (
    <FormControl
      variant="filled"
      sx={{
        m: margin,
        width: '100%',
        '.MuiInputBase-root': {
          border: `1px solid ${rest.error ? 'red' : '#D9D9D9'}!important`,
        },
        '.MuiInputBase-root, .MuiSelect-select.MuiSelect-filled:focus': {
          bgcolor: '#fff!important',
          borderRadius: bRadius,
          '&:before, &:after': {
            borderBottom: '0px!important',
          },
          '.MuiSelect-select': {
            pr: '40px',
            color: '#281C58',
            fontSize: 14,
            fontWeight: 600,
            pb: '5px!important',
          },
        },
        '.MuiFormLabel-root, .MuiFormLabel-root.Mui-focused': {
          color: `${rest.error ? 'red' : '#5A5A5A'}!important`,
          opacity: rest.error ? 1 : 0.5,
        },
      }}
      {...rest}
    >
      <InputLabel id="demo-simple-select-filled-label">{label}</InputLabel>
      <Select value={value} onChange={onChange}>
        {Object.entries(options).map((option: any, index: number) => (
          <MenuItem
            key={`select-option-${index}-${option[0]}`}
            value={option[0]}
          >
            {option[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
