import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { capitalize, sum } from 'lodash'
import { serviceQualityTypes } from '../../../types/subscription.types'

const calculateTotal = (subtotal: number, percentDiscount = 0) => {
  //return the subtotal  if no discount is specified
  if (!percentDiscount) return subtotal.toFixed(2)

  //calculate  discount if specified
  const discount = subtotal * (percentDiscount / 100)

  //subtract discount to subtotal and get the decimal amount
  return (subtotal - discount).toFixed(2)
}

const OrderSummary = (props: any) => {
  const {
    selectedPackages,
    onSelectPackage,
    coupon,
    setCoupon,
    onChange,
    isDesktop,
  } = props

  const subTotal = Object.values(selectedPackages)
    .filter((e: any | null) => e)
    .map((e: any | null) => e?.rate ?? 0)

  const [totalPrice, totalCents] = sum(subTotal)
    .toFixed(2)
    .toString()
    .split('.')

  return (
    <Box
      sx={{
        p: isDesktop ? '20px 40px' : '20px 0px',
      }}
    >
      {Object.entries(selectedPackages).map(
        ([type, sp]: [string, any | null], index: number) => {
          const quality = (serviceQualityTypes as any)[sp?.quality ?? '']?.label
          const typeLabel = capitalize(sp?.type ?? '')
          if (!sp) return null

          return (
            <Box
              key={`package-sl-${index}`}
              sx={{
                background: ' #4862FF',
                borderRadius: '10px',
                padding: '20px',
                color: '#FFFFFF',
                '& h4': {
                  margin: '0',
                  marginBottom: '20px',
                },
                '& p': {
                  margin: '0',
                  marginBottom: '5px',
                  color: '#FFFFFF',
                  opacity: '0.5',
                },
                mb: 3,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <span>{capitalize(type)}</span>
                <CloseIcon
                  sx={{
                    float: 'right',
                    cursor: 'pointer',
                  }}
                  onClick={() => onSelectPackage(null, sp?.type ?? '')}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: 2,
                  '& p': {
                    fontSize: '12px',
                  },
                  '& b': {
                    fontSize: '14px',
                    textAlign: 'right',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <p>Type</p>
                  <b>{quality}</b>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    '& span': {
                      fontSize: '12px',
                      fontWeight: 'normal',
                      opacity: '0.5',
                    },
                  }}
                >
                  <p>Package</p>
                  <b>
                    {sp.quantity} {typeLabel} <br />
                    <span>per post</span>
                  </b>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    '& span': {
                      fontSize: '14px',
                      fontWeight: 'normal',
                    },
                  }}
                >
                  <p>Price</p>
                  <b>
                    ${sp.rate} <span>/month</span>
                  </b>
                </Box>
              </Box>
            </Box>
          )
        }
      )}

      <Box
        className="separator"
        sx={{
          margin: '10px 5px 20px',
          borderBottom: '1px solid #e5e5e5',
          height: '1px',
        }}
      />
      <Box
        sx={{
          '& .list': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            textAlign: 'right',
            alignItems: 'center',
            mt: '10px',
            '& .subtotal': {
              color: '#5A5A5A',
              fontSize: '12px',
            },
            '& .plan-price': {
              marginBlock: 'unset',
              color: '#281C58',
              fontSize: '24px',
              span: {
                fontSize: '14px',
              },
            },
            '& .plan-discount': {
              marginBlock: 'unset',
              color: '#281C58',
              fontSize: '16px',
            },
          },
        }}
      >
        <Box className="list" sx={{}}>
          <p className="subtotal">Subtotal</p>
          <div>
            <p className="plan-price">
              $
              <b>
                {totalPrice}.{totalCents}
              </b>
            </p>
          </div>
        </Box>

        {coupon && (
          <Box className="list">
            <p className="subtotal">Discount </p>
            <div>
              <p className="plan-discount">
                <b>{coupon?.discount_percentage}%</b>
                <CloseIcon
                  sx={{
                    fontSize: '13px',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                  onClick={(e) => {
                    onChange('code', '')(e)
                    setCoupon('')
                  }}
                />
              </p>
            </div>
          </Box>
        )}
        <Box
          className="separator"
          sx={{
            margin: '10px 5px 20px',
            borderBottom: '1px solid #e5e5e5',
            height: '1px',
          }}
        />
        <Box className="list" sx={{}}>
          <p className="subtotal">Grand Total</p>
          <div>
            <p className="plan-price">
              $
              <b>
                {calculateTotal(sum(subTotal), coupon?.discount_percentage)}
              </b>
            </p>
          </div>
        </Box>
      </Box>

      <Box
        className="separator"
        sx={{
          margin: '10px 5px 20px',
          borderBottom: '1px solid #e5e5e5',
          height: '1px',
        }}
      />
    </Box>
  )
}

export default OrderSummary
