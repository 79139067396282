import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useContext } from 'react'
import { AppContext } from '../../Protected'
import RectangularSkeleton from '../skeletons/rectangular.skeleton'

interface THeader {
  title?: string
  aditionalFilters?: any
  total?: number
  sortByOptions?: any
  onSort?: Function
  sort?: string
  isLoading?: boolean
}

const TableHeader = (props: THeader) => {
  const {
    title,
    aditionalFilters,
    total = 0,
    sortByOptions = {},
    onSort = () => {},
    sort = 'all',
    isLoading = true,
  } = props
  const { isDesktop } = useContext(AppContext)

  const formProps = () => {
    if (isDesktop) {
      return { size: 'small', fullWidth: false }
    } else {
      return { fullWidth: true }
    }
  }

  const handleSort = (event: SelectChangeEvent<string>) => {
    onSort(event.target.value as string)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isDesktop ? 'center' : 'left',
        flexDirection: isDesktop ? 'row' : 'column-reverse',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: isDesktop ? 'center' : 'space-between',
          mt: isDesktop ? 'unset' : 2,
        }}
      >
        <Typography
          component="h2"
          sx={{
            color: '#261F72',
            fontSize: '24px',
            fontWeight: '700',
            mr: 2,
          }}
        >
          {title}
        </Typography>
        {!isLoading && (
          <Typography
            component="h4"
            sx={{ color: '#FF4EA3', fontSize: '18px', fontWeight: '600' }}
          >
            {total}
          </Typography>
        )}
      </Box>
      <Box display={isDesktop ? 'flex' : 'block'} alignItems="center">
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          alignItems={isDesktop ? 'center' : 'left'}
        >
          <Typography
            color="#261F72"
            fontSize={isDesktop ? 14 : 18}
            fontWeight={400}
            sx={{ mr: 2, mb: isDesktop ? '0' : '20px' }}
          >
            Sort By:
          </Typography>
          {isLoading ? (
            <RectangularSkeleton
              width="100px"
              height="40px"
              sx={{ borderRadius: '10px' }}
            />
          ) : (
            <FormControl {...formProps}>
              <Select
                value={sort}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    borderStyle: 'none',
                  },
                  bgcolor: isDesktop ? '#ffdcf3' : '#fff',
                  fontSize: '14px',
                  color: '#261F72',
                  borderRadius: '10px',
                  '.MuiSelect-outlined': {
                    padding: isDesktop ? '12px 14px' : '16.5px 14px',
                  },
                }}
                onChange={handleSort}
              >
                {Object.entries(sortByOptions).map(([key, label]: any) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Stack>
        {aditionalFilters && aditionalFilters}
      </Box>
    </Box>
  )
}

export default TableHeader
